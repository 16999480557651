import React, { useCallback } from 'react';
import styled from 'styled-components';
import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';

export interface CheckboxType {
    label: string;
    isChecked: boolean;
}

interface Props {
    options: CheckboxType[];
    setActiveOptions: (value: CheckboxType[]) => void;
    className?: string;
}

export const CheckboxSelector = ({ options, setActiveOptions, className }: Props) => {
    const onChangePassThrough = useCallback(
        (evt) => {
            const toggleValue = evt.target.value;
            const newOptionsArray: CheckboxType[] = [];
            options.forEach((option) => {
                if (option.label === toggleValue) {
                    newOptionsArray.push({
                        label: option.label,
                        isChecked: !option.isChecked,
                    });
                } else {
                    newOptionsArray.push({
                        label: option.label,
                        isChecked: option.isChecked,
                    });
                }
            });
            setActiveOptions(newOptionsArray);
        },
        [options]
    );

    return (
        <FormGroup className={className} onChange={onChangePassThrough}>
            {options.map((opt) => (
                <NarrowFormControlLabel
                    key={opt.label}
                    value={opt.label}
                    control={<Checkbox checked={opt.isChecked} />}
                    label={opt.label}
                />
            ))}
        </FormGroup>
    );
};

const NarrowFormControlLabel = styled(FormControlLabel)`
    &&& {
        height: 30px;
        .MuiFormControlLabel-label {
            line-height: 1;
        }
    }
`;
