import React, { useContext, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useFetch } from 'use-http';
import { debounce } from '@mui/material/utils';

import { MapOptionsContext } from '../context/MapOptionsContext';

interface SearchResponse {
    features?: SearchResult[];
}

interface SearchResult {
    properties: {
        address?: string;
    };
    text_en: string;
    place_name_en: string;
    bbox?: [number, number, number, number]; // bounding box
    center: [number, number];
}

export const Search = () => {
    const { mapboxglAccess, view, setView, setFitToBounds } = useContext(MapOptionsContext);
    const [options, setOptions] = useState<SearchResult[]>([]);
    const {
        get: searchMapbox,
        data,
        loading,
    } = useFetch<SearchResponse>('https://api.mapbox.com/geocoding/v5/mapbox.places');

    useEffect(() => {
        setOptions(data?.features || []);
    }, [data]);

    const doSearch = useMemo(
        () =>
            debounce((searchText: string) => {
                const latLon = searchText.match(
                    /(?<lat>[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)),\s*(?<lon>[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?))/
                );
                const latitude = Number(latLon?.groups && latLon.groups.lat);
                const longitude = Number(latLon?.groups && latLon.groups.lon);
                if (
                    latLon?.groups &&
                    latLon.groups.lat &&
                    latLon.groups.lon &&
                    !isNaN(latitude) &&
                    !isNaN(longitude)
                ) {
                    setOptions([
                        {
                            properties: {},
                            text_en: searchText,
                            place_name_en: `Latitude: ${latitude}, Longitude: ${longitude}`,
                            center: [longitude, latitude],
                        },
                    ]);
                } else if (mapboxglAccess.token) {
                    searchMapbox(
                        `/${encodeURI(searchText)}.json?proximity=ip&language=en&access_token=${
                            mapboxglAccess.token
                        }`
                    );
                }
            }, 400),
        [mapboxglAccess, setOptions]
    );

    return (
        <Autocomplete<SearchResult>
            disabled={!mapboxglAccess.token}
            getOptionLabel={(option: SearchResult) => option.text_en}
            options={options}
            autoComplete
            filterSelectedOptions
            noOptionsText="No locations"
            loading={loading}
            onChange={(_: React.SyntheticEvent, newValue: SearchResult | null) => {
                if (newValue) {
                    if (newValue.bbox) {
                        setFitToBounds({
                            location: [
                                [newValue.bbox[0], newValue.bbox[1]],
                                [newValue.bbox[2], newValue.bbox[3]],
                            ],
                        });
                    } else {
                        setView({
                            ...view,
                            latitude: newValue.center[1],
                            longitude: newValue.center[0],
                            zoom: 16,
                        });
                    }
                }
            }}
            onInputChange={(_, newInputValue) => {
                doSearch(newInputValue);
            }}
            renderInput={(params) => (
                <Input
                    {...params}
                    placeholder="Search…"
                    fullWidth
                    variant="filled"
                    size="small"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            renderOption={(props, option: SearchResult) => {
                return (
                    <li {...props} key={option.text_en}>
                        <Grid container alignItems="center">
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                <Typography variant="body2">{option.text_en}</Typography>
                                <Typography variant="body1">
                                    {option.properties.address || option.place_name_en}
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
};

const Input = styled(TextField)`
    &&& {
        min-width: 100px;
        .MuiFilledInput-root {
            padding: ${({ theme }) => theme.spacing(1)};
            border-radius: ${({ theme }) => theme.shape.borderRadius}px;
            ::before {
                border: 0;
            }
            .MuiInputAdornment-root {
                margin-top: 0;
            }
        }
    }
`;
