import React from 'react';
import styled from 'styled-components';
import { Accordion, AccordionDetails, AccordionProps, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface Props extends AccordionProps {
    header: React.ReactNode;
}

export const AccordionDrawer = ({ header, children, ...rest }: Props) => {
    return (
        <BorderlessAccordion defaultExpanded={true} disableGutters {...rest}>
            <AccordionSummaryIconLeft expandIcon={<ExpandMoreIcon />}>
                {header}
            </AccordionSummaryIconLeft>
            <CleanAccordionDetails>{children}</CleanAccordionDetails>
        </BorderlessAccordion>
    );
};

const BorderlessAccordion = styled(Accordion)`
    &&& {
        border-radius: ${({ theme }) => theme.spacing(1)};
        ::before {
            background: transparent;
        }
    }
`;

const AccordionSummaryIconLeft = styled(AccordionSummary)`
    &&& {
        background-color: ${({ theme }) => theme.color2.N2};
        border-radius: ${({ theme }) => theme.spacing(1)};
        flex-direction: row-reverse;
        .MuiAccordionSummary-expandIconWrapper {
            transform: rotate(-90deg);
            &.Mui-expanded {
                transform: rotate(0deg);
            }
        }
        .MuiAccordionSummary-content {
            p {
                font-weight: bold;
            }
            margin-left: ${({ theme }) => theme.spacing(1)};
        }
    }
`;

const CleanAccordionDetails = styled(AccordionDetails)`
    &&& {
        padding: ${({ theme }) =>
            `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)}`};
        background: white;
        border-radius: ${({ theme }) => theme.spacing(1)};
    }
`;
