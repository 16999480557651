import { Sources } from 'mapbox-gl';

import { DateOption, options as dates } from '../components/YearSelector';

const AllenAIAttribution = `<span>©${' '}
<a href="https://allenai.org">The Allen Institute for Artificial Intelligence</a> - All Rights Reserved
</span>`;

const OSMAttribution = '<a href="https://www.openstreetmap.org/copyright">© OpenStreetMap</a>';

// options set that affect sources fetching
interface SelectedMapOptions {
    selectedStartDateOption: DateOption;
    selectedEndDateOption: DateOption;
}

export enum MapSources {
    HistoryMarineInfra = 'historyMarine',
    HistoryTreeCover = 'historyTreeCover',
    HistoryRenewableEnergy = 'historyRenewable',
    ChangeOverTimeTreeCover = 'changeOverTimeTreeCover',
    SuperResolution = 'superResolution',
}

// sentinel2 images at a specific date/time combo without any holes for super resolution.
// this is special-cased, but will change once we add support for more 2023 dates.
export const sentinel2023SpecialDate = 'sr2023';

export const getSources = (getMapOptions: () => SelectedMapOptions): Sources => {
    const mapOptions = getMapOptions();
    const startDate = mapOptions.selectedStartDateOption;
    const endDate = mapOptions.selectedEndDateOption;

    const ret: Sources = {};
    // osm
    ret['mapbox-streets'] = {
        type: 'raster',
        tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
        attribution: OSMAttribution,
        tileSize: 256,
        minzoom: 0,
        maxzoom: 23,
    };

    dates.forEach((date) => {
        // sentinel2 for each month/year combo
        ret[`sentinel2${date}`] = {
            type: 'raster',
            tiles: [`https://se-tile-api.allen.ai/mosaic/sentinel2/${date}/tci/{z}/{x}/{y}.webp`],
            tileSize: 512,
            attribution: AllenAIAttribution,
            minzoom: 0,
            maxzoom: 23,
        };
    });

    // sentinel2 for April 2023, a specific date/time combo for super resolution
    ret[`sentinel2${sentinel2023SpecialDate}`] = {
        type: 'raster',
        tiles: [
            `https://se-tile-api.allen.ai/mosaic/sentinel2/${sentinel2023SpecialDate}/tci/{z}/{x}/{y}.webp`,
        ],
        tileSize: 512,
        attribution: AllenAIAttribution,
        minzoom: 0,
        maxzoom: 23,
    };

    // tree cover diff across two years
    ret[MapSources.ChangeOverTimeTreeCover] = {
        type: 'vector',
        tiles: [
            `https://se-tile-api.allen.ai/output_change/tree-cover/${startDate}/${endDate}/{z}/{x}/{y}`,
        ],
        attribution: AllenAIAttribution,
        minzoom: 0,
        maxzoom: 23,
    };

    // tree cover across all time
    ret[MapSources.HistoryTreeCover] = {
        type: 'vector',
        tiles: ['https://se-tile-api.allen.ai/output_history/tree-cover/{z}/{x}/{y}'],
        attribution: AllenAIAttribution,
        minzoom: 0,
        maxzoom: 23,
    };

    // marine infra across all time
    ret[MapSources.HistoryMarineInfra] = {
        type: 'vector',
        tiles: ['https://se-tile-api.allen.ai/output_history/marine-default-cluster/{z}/{x}/{y}'],
        attribution: AllenAIAttribution,
        minzoom: 0,
        maxzoom: 23,
    };

    // renewable energy across all time
    ret[MapSources.HistoryRenewableEnergy] = {
        type: 'vector',
        tiles: [
            'https://se-tile-api.allen.ai/output_history/renewable-default-cluster/{z}/{x}/{y}',
        ],
        attribution: AllenAIAttribution,
    };

    ret[MapSources.SuperResolution] = {
        type: 'raster',
        tiles: ['https://se-tile-api.allen.ai/mosaic/superres/sr2023/tci/{z}/{x}/{y}.webp'],
        tileSize: 512,
        attribution: AllenAIAttribution,
        minzoom: 0,
        maxzoom: 23,
    };

    return ret;
};
