export const getAddedTimeFilter = (dateStart: string, dateEnd: string) => {
    return [
        'all',
        ['>', ['get', 'start'], dateStart],
        ['<=', ['get', 'start'], dateEnd],
        ['>', ['get', 'end'], dateEnd],
    ];
};

export const getRemovedTimeFilter = (dateStart: string, dateEnd: string) => {
    return [
        'all',
        ['>', ['get', 'end'], dateStart],
        ['<=', ['get', 'end'], dateEnd],
        ['<=', ['get', 'start'], dateStart],
    ];
};
export const getUnchangedTimeFilter = (dateStart: string, dateEnd: string) => {
    return ['all', ['<=', ['get', 'start'], dateStart], ['>', ['get', 'end'], dateEnd]];
};

export const addedTreeCoverFilter = ['==', ['get', 'change'], 'added'];

export const removedTreeCoverFilter = ['==', ['get', 'change'], 'removed'];

export const unchangedTreeCoverFilter = ['==', ['get', 'change'], 'unchanged'];
