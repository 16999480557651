import { color2 } from '@allenai/varnish2/theme';

export const NoneKey = 'none';
export const LowKey = 'low';
export const MediumKey = 'medium';
export const HighKey = 'high';
export const FullKey = 'full';

export const getTreeCoverColors = (fillLevel: string) => {
    switch (fillLevel.toLowerCase()) {
        case LowKey:
            return color2.O2.hex;
        case MediumKey:
            return color2.G3.hex;
        case HighKey:
            return color2.G4.hex;
        case FullKey:
            return color2.G5.hex;
        case NoneKey:
        default:
            return color2.O1.hex;
    }
};

export const getTreeCoverLabelColor = (fillLevel: string) => {
    switch (fillLevel.toLowerCase()) {
        case NoneKey:
            return color2.N5.hex;
        case LowKey:
            return color2.N5.hex;
        default:
            return color2.N1.hex;
    }
};

export const getTreeCoverUnit = (_fillLevel?: string) => {
    return 'km\u00B2';
};

export const getTreeCoverOrder = (fillLevel: string) => {
    switch (fillLevel.toLowerCase()) {
        case LowKey:
            return 3;
        case MediumKey:
            return 2;
        case HighKey:
            return 1;
        case FullKey:
            return 0;
        case NoneKey:
        default:
            return 4;
    }
};
