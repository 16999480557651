/*
  Slider that takes in a set of ordered options and allows the user to slide from one to the next.
  It does this by using the index of the option as the number value for the slider.
*/

import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { SliderValueLabelProps, Tooltip, Box } from '@mui/material';

import { BaseSlider, sliderHeightScalingValue } from './BaseSlider';

interface Props<T> {
    options: T[];
    optionsToLabel: T[];
    value: T;
    onChange: (value: T) => void;
    className?: string;
}

export const CategoricalSliderSelector = <T extends string>({
    options,
    optionsToLabel,
    value,
    onChange,
    className,
}: Props<T>) => {
    // local number value to be used as the slider value
    const [numberValue, setNumberValue] = useState<number>(options.length - 1 || 1);

    const onChangePassThrough = useCallback(
        (evt) => {
            // this updates value
            onChange(convertNumberToT(evt.target.value));
        },
        [onChange]
    );

    useEffect(() => {
        // update local number value when option value changes
        setNumberValue(convertTtoNumber(value));
    }, [value]);

    const convertNumberToT = (value: number) => {
        return options[value];
    };

    const convertTtoNumber = (value: T) => {
        return options.indexOf(value);
    };

    const ValueLabelComponent = (props: SliderValueLabelProps) => {
        const { children, value } = props;
        return (
            <Tooltip enterTouchDelay={0} placement="top" title={value}>
                {children}
            </Tooltip>
        );
    };

    return (
        <Container sx={{ height: `${optionsToLabel.length * sliderHeightScalingValue}px` }}>
            <BaseSlider
                className={className}
                track={false}
                value={numberValue}
                orientation="vertical"
                onChange={onChangePassThrough}
                getAriaValueText={convertNumberToT}
                valueLabelFormat={convertNumberToT}
                min={0}
                max={options.length - 1 || 1}
                step={1}
                valueLabelDisplay="auto"
                slots={{
                    valueLabel: ValueLabelComponent,
                }}
                marks={optionsToLabel.map((v) => {
                    return { value: options.indexOf(v), label: v };
                })}
            />
        </Container>
    );
};

const Container = styled(Box)`
    margin: ${({ theme }) => `${theme.spacing(2)} 0 ${theme.spacing(2)} ${theme.spacing(3)}`};
`;
