import React, { useContext, useEffect } from 'react';
import { Typography } from '@mui/material';

import { CategoricalSliderSelector } from './baseSelectors/CategoricalSliderSelector';
import { MapOptionsContext } from '../context/MapOptionsContext';
import { LayoutOption } from './LayoutSelector';
import { RangeSliderSelector } from './baseSelectors/RangeSliderSelector';
import { AnimationStartStopSlider } from './AnimationStartStopSlider';

export enum YearOption {
    Year2016 = '2016',
    Year2017 = '2017',
    Year2018 = '2018',
    Year2019 = '2019',
    Year2020 = '2020',
    Year2021 = '2021',
    Year2022 = '2022',
    Year2023 = '2023',
}

export enum MonthOption {
    Jan = '01',
    Feb = '02',
    March = '03',
    April = '04',
    May = '05',
    June = '06',
    July = '07',
    Aug = '08',
    Sep = '09',
    Oct = '10',
    Nov = '11',
    Dec = '12',
}

// represents the full range of valid dates supported by the api
export const DataTimeRange = {
    start: { year: YearOption.Year2016, month: MonthOption.Jan },
    end: { year: YearOption.Year2023, month: MonthOption.Dec },
};

const getDateOptions = () => {
    const yearOptions = Object.values(YearOption);
    const monthOptions = Object.values(MonthOption);
    const finalOptionsArr: string[] = [];
    yearOptions.forEach((year) => {
        monthOptions.forEach((month) => {
            finalOptionsArr.push(year + '-' + month);
        });
    });
    return finalOptionsArr;
};

export const options = getDateOptions();
export type DateOption = (typeof options)[0];

interface Props {}

const getImportantOptions = () => {
    // this shows Jan of every year + Dec of the last year to indicate the ending metric on the sider
    return options.filter((_, i) => Math.round(i / 12) === i / 12 || i === options.length - 1);
};

export const YearSelector = ({ ...rest }: Props) => {
    const {
        selectedDateOption,
        setSelectedDateOption,
        selectedDateOptionSecondary,
        setSelectedDateOptionSecondary,
        selectedStartDateOption,
        setSelectedStartDateOption,
        selectedEndDateOption,
        setSelectedEndDateOption,
        selectedLayout,
        showAnimation,
    } = useContext(MapOptionsContext);

    // this is the animation loop
    useEffect(() => {
        if (showAnimation === true) {
            let currIndex = options.indexOf(selectedDateOption);
            const interval = setInterval(() => {
                // increment currIndex each time to progress to the next year,
                // and reset to 0 when gone through all years
                currIndex++;
                if (
                    currIndex < options.indexOf(selectedStartDateOption) ||
                    currIndex > options.indexOf(selectedEndDateOption)
                ) {
                    currIndex = options.indexOf(selectedStartDateOption);
                }
                setSelectedDateOption(options[currIndex]);
            }, 500);

            return () => clearInterval(interval);
        }
    }, [showAnimation, selectedDateOption, selectedStartDateOption, selectedEndDateOption]);

    if (selectedLayout === LayoutOption.ChangeOverTime) {
        return (
            <RangeSliderSelector<DateOption>
                options={options}
                optionsToLabel={getImportantOptions()}
                startValue={selectedStartDateOption}
                endValue={selectedEndDateOption}
                onStartValueChange={setSelectedStartDateOption}
                onEndValueChange={setSelectedEndDateOption}
                {...rest}
            />
        );
    }
    if (showAnimation) {
        return (
            <AnimationStartStopSlider
                options={options}
                optionsToLabel={getImportantOptions()}
                curValue={selectedDateOption}
                startValue={selectedStartDateOption}
                endValue={selectedEndDateOption}
                onCurValueChange={setSelectedDateOption}
                onStartValueChange={setSelectedStartDateOption}
                onEndValueChange={setSelectedEndDateOption}
                {...rest}
            />
        );
    }
    if (selectedLayout !== LayoutOption.Single) {
        return (
            <>
                <Typography>Left:</Typography>
                <CategoricalSliderSelector<DateOption>
                    options={options}
                    optionsToLabel={getImportantOptions()}
                    value={selectedDateOption}
                    onChange={setSelectedDateOption}
                    {...rest}
                />
                <Typography>Right:</Typography>
                <CategoricalSliderSelector<DateOption>
                    options={options}
                    optionsToLabel={getImportantOptions()}
                    value={selectedDateOptionSecondary}
                    onChange={setSelectedDateOptionSecondary}
                    {...rest}
                />
            </>
        );
    } else {
        return (
            <CategoricalSliderSelector<DateOption>
                options={options}
                optionsToLabel={getImportantOptions()}
                value={selectedDateOption}
                onChange={setSelectedDateOption}
                {...rest}
            />
        );
    }
};
