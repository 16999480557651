import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import MapGl, { MapProvider, MapRef, MapboxEvent } from 'react-map-gl';
import { Map as mapboxGlMap } from 'mapbox-gl';

import { getSources } from '../data/sources';
import { getLayers } from '../data/layers';
import { MapOptionsContext } from '../context/MapOptionsContext';

import { LayoutOption } from './LayoutSelector';
import { FeatureData } from './details/FeatureDetails';

import 'mapbox-gl/dist/mapbox-gl.css';

interface Props {
    getFeatureData: (target: mapboxGlMap) => FeatureData;
    isSecondary?: boolean;
}

export const Map = ({ getFeatureData, isSecondary }: Props) => {
    const boundsRef = useRef<HTMLDivElement>(null);
    const mapRef = useRef<MapRef>(null);
    const [padding, setPadding] = useState({ left: 0, top: 0, right: 0, bottom: 0 });

    const {
        mapboxglAccess,
        view,
        setView,
        showHeatmap,
        selectedDateOption,
        selectedDateOptionSecondary,
        selectedStartDateOption,
        selectedEndDateOption,
        showSatellite,
        showSuperResolution,
        hideAnnotation,
        selectedTreeCoverFill,
        selectedMarineInfra,
        selectedRenewableEnergy,
        selectedApplication,
        selectedLayout,
        setFeatureDetails,
        setFeatureDetailsSecondary,
        fitToBounds,
        setFitToBounds,
        setMouseLatLon,
    } = useContext(MapOptionsContext);

    // when fitToBounds is altered in context, we have the main map do the action and then clear
    // the fitbounds in context. We could make some kind of eventing system in the future if we do
    // more of this kind of thing.
    useEffect(() => {
        if (!isSecondary && fitToBounds && mapRef.current) {
            mapRef.current.fitBounds(fitToBounds.location, { padding: fitToBounds.padding });
            setFitToBounds(undefined);
        }
    }, [fitToBounds, mapRef]);

    useEffect(() => {
        const map = mapRef.current?.getMap();
        if (map) {
            map.resize();
        }
        onResize();
    }, [selectedLayout]);

    const onResize = () => {
        if (boundsRef.current) {
            const b = boundsRef.current.getBoundingClientRect().width;
            if (!isSecondary) {
                setPadding({
                    left: selectedLayout === LayoutOption.Split ? b : 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                });
            } else {
                setPadding({
                    right: selectedLayout === LayoutOption.Split ? b : 0,
                    top: 0,
                    left: 0,
                    bottom: 0,
                });
            }
        }
    };

    const onRender = (e: MapboxEvent) => {
        e.target?.resize();
        if (e.target) {
            const featureDataMap = getFeatureData(e.target);
            if (!isSecondary) {
                setFeatureDetails(featureDataMap);
            } else {
                setFeatureDetailsSecondary(featureDataMap);
            }
        }
    };

    return (
        <MapProvider>
            <OuterContainer onMouseLeave={() => setMouseLatLon()} ref={boundsRef}>
                {mapboxglAccess.error ? <span>MapBox Token Error. See README.</span> : null}
                {!mapboxglAccess.token ? null : (
                    <MapGl
                        ref={mapRef}
                        reuseMaps
                        mapboxAccessToken={mapboxglAccess.token}
                        {...view}
                        padding={padding}
                        onMouseMove={(evt) =>
                            setMouseLatLon([evt.lngLat.wrap().lat, evt.lngLat.wrap().lng])
                        }
                        onMove={(evt) => setView && setView(evt.viewState)}
                        onResize={onResize}
                        onRender={onRender}
                        attributionControl={true}
                        mapStyle={{
                            version: 8,
                            glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
                            sources: getSources(() => {
                                return {
                                    selectedStartDateOption,
                                    selectedEndDateOption,
                                };
                            }),
                            layers: getLayers(() => {
                                return {
                                    selectedApplication,
                                    selectedLayout,
                                    showHeatmap,
                                    showSatellite,
                                    showSuperResolution,
                                    hideAnnotation,
                                    selectedTreeCoverFill,
                                    selectedMarineInfra,
                                    selectedRenewableEnergy,
                                    selectedDateOption,
                                    selectedStartDateOption,
                                    selectedEndDateOption,
                                    selectedDateOptionSecondary,
                                    isSecondary: isSecondary || false,
                                };
                            }),
                        }}></MapGl>
                )}
            </OuterContainer>
        </MapProvider>
    );
};

const OuterContainer = styled.div`
    height: 100%;
    width: 100%;
`;
