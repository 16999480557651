import React, { useRef } from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@mui/material';
import { Footer, MaxWidthText } from '@allenai/varnish2/components';

import { SuperResSlideViewer } from '../components/SuperResSlideViewer';
import {
    FullWidthImageWithAttribution,
    MainContainer,
    MaxWidthContent,
    NarrowContent,
    StackableBlackSection,
    StackableGreySection,
    StackableSection,
} from '../components/SharedContainers';
import { SubPageHero } from '../components/SubPageHero';

import superResModel from '../images/super-res/super-res-model.svg';
import fuSentinelSrc from '../images/super-res/frequent-updates_01-sentinel.jpg';
import fuGoogleMapsSrc from '../images/super-res/frequent-updates_02-googlemaps.jpg';
import fuSuperResSrc from '../images/super-res/frequent-updates_03-super-res.jpg';
import timelapseVideoSrc from '../video/timelapse.mp4';

import { CenterAlignedDiv } from '../components/utils/sharedStyles';

export const SuperResGallery = () => {
    const videoRef = useRef<HTMLVideoElement>(null);

    return (
        <MainContainer>
            <SubPageHero>
                <h1>Super-Resolution </h1>
            </SubPageHero>

            <StackableSection>
                <MaxWidthText>
                    <SuperResSlideViewer />
                </MaxWidthText>
                <NarrowContent>
                    Super-Resolution allows a very high level of detail compared to Sentinel-2
                    images.
                    <br />
                </NarrowContent>
            </StackableSection>

            <StackableGreySection>
                <MaxWidthText>
                    <h2>Introduction</h2>
                    <Grid container columnSpacing={7}>
                        <Grid item xs={12} sm={6}>
                            <Typography paragraph>
                                Global low resolution satellite imagery is available on a weekly
                                basis, but public high resolution imagery is very limited. High
                                resolution imagery can help with tasks such as post-disaster
                                building damage estimation and crop type classification, but since
                                it is available infrequently, we cannot scale up promising AI
                                methods to automate these tasks, especially in developing countries
                                where it is needed most.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography paragraph>
                                We have trained super resolution models to generate high resolution
                                imagery on a global scale.
                            </Typography>
                            <Typography paragraph>
                                The <a href="/map">Satlas Map</a> allows users to explore globally
                                generated high resolution imagery for 2023.
                            </Typography>
                        </Grid>
                    </Grid>
                </MaxWidthText>
            </StackableGreySection>

            <StackableBlackSection>
                <h2>Frequent Updates</h2>
                <NarrowContent>
                    Super-Resolution is generated from 2023 Sentinel-2 imagery, and is sometimes
                    more up-to-date than Google Maps. This example of Cebu City, Phillippines shows
                    evidence of coastal infrastructure in both Sentinel-2 and the generated
                    Super-Resolution but not in the Google Maps image.
                </NarrowContent>
                <MaxWidthContent>
                    <FrequentUpdateGrid container columnSpacing={2.5} rowSpacing={2}>
                        <Grid item sm={12} md={4}>
                            <img src={fuSentinelSrc} height={186} alt="2023 Sentinel-2" />
                            <Typography variant="caption" display="block">
                                2023 Sentinel-2 image
                            </Typography>
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <img src={fuGoogleMapsSrc} height={186} alt="Google Maps (outdated)" />
                            <Typography variant="caption" display="block">
                                Google Maps image (outdated)
                            </Typography>
                        </Grid>
                        <Grid item sm={12} md={4}>
                            <img src={fuSuperResSrc} height={186} alt="2023 Satlas Super-Res" />
                            <Typography variant="caption" display="block">
                                2023 Satlas Super-Resolution
                            </Typography>
                        </Grid>
                    </FrequentUpdateGrid>
                </MaxWidthContent>
            </StackableBlackSection>

            <StackableGreySection>
                <MaxWidthText>
                    <h2>Change Over Time</h2>
                    <NarrowContent>
                        With increased resolution and cloud-free views, Super-Resolution's enhanced
                        imagery makes it easier to see change over time.
                    </NarrowContent>
                </MaxWidthText>
                <MaxWidthContent>
                    <TimelapseVideo
                        ref={videoRef}
                        src={timelapseVideoSrc}
                        controls
                        autoPlay
                        loop
                        muted
                        onLoadStart={() => {
                            if (videoRef.current) {
                                videoRef.current.playbackRate = 0.25;
                            }
                        }}>
                        <source type="video/mp4"></source>
                    </TimelapseVideo>
                    <NarrowContent>
                        <Typography variant="caption" display="block">
                            Sinop Dam in Brazil filling up, including removal of trees and dirt to
                            build pathways for the water.
                        </Typography>
                    </NarrowContent>
                </MaxWidthContent>
            </StackableGreySection>

            <StackableSection>
                <MaxWidthContent>
                    <h2>Super-Resolution Model</h2>
                    <NarrowContent>
                        The Super-Resolution imagery is generated using an adaptation of the{' '}
                        <a
                            href="https://arxiv.org/abs/1809.0021"
                            target="_blank"
                            rel="noopener noreferrer">
                            ESRGAN model
                        </a>
                        . It is trained on pairs of corresponding Sentinel-2 (low resolution) and
                        NAIP (high resolution) images. Our model inputs a sequence of between 6-18
                        Sentinel-2 images for each location.
                    </NarrowContent>
                    <FullWidthImageWithAttribution>
                        <img src={superResModel} alt="Super-Resolution Model" />
                        <Typography variant="caption" display="block">
                            Super-Resolution Model Architecture
                        </Typography>
                    </FullWidthImageWithAttribution>
                    <NarrowContent>
                        The training and inference code is open source and can be found at our{' '}
                        <a
                            href="https://github.com/allenai/satlas-super-resolution"
                            target="_blank"
                            rel="noopener noreferrer">
                            Github
                        </a>
                        . We note that AI models are not perfect and there are instances where the
                        super-resolution outputs are incorrect.
                    </NarrowContent>
                </MaxWidthContent>
            </StackableSection>
            <CenterAlignedDiv>
                <Footer />
            </CenterAlignedDiv>
        </MainContainer>
    );
};

const FrequentUpdateGrid = styled(Grid)`
    padding: ${({ theme }) => `${theme.spacing(8.5)} ${theme.spacing(3)} 0 ${theme.spacing(3)}`};
    justify-content: center;

    img {
        display: block;
        margin: 0 auto;
        object-fit: cover;
        width: 100%;
        max-width: 500px;
        max-height: 186px;
    }

    .MuiTypography-caption {
        margin-top: ${({ theme }) => theme.spacing(2)};
        text-align: center;
        color: white;
    }
`;

const TimelapseVideo = styled.video`
    display: block;
    width: 100%;
    max-width: 1120px;
    margin: ${({ theme }) => theme.spacing(4)} auto;
    height: auto;
    aspect-ratio: 2 / 1;
    background: ${({ theme }) => theme.color2.N3};
`;
