import React from 'react';
import styled from 'styled-components';
import {
    Typography,
    Card,
    CardContent,
    CardMedia,
    CardActionArea,
    Grid,
    Button,
} from '@mui/material';
import { Footer, MaxWidthText } from '@allenai/varnish2/components';

import { SuperResSlideViewer } from '../components/SuperResSlideViewer';
import {
    MainContainer,
    StackableGreySection,
    StackableSection,
    FullWidthImageWithAttribution,
} from '../components/SharedContainers';
import { SatlasHero } from '../components/SatlasHero';
import { Credits } from '../components/Credits';

import priorSrc from '../images/prior-transparent.svg';
import shareFacebookSrc from '../images/social-facebook-32px.svg';
import shareTwitterSrc from '../images/social-twitter-32px.svg';
import shareLinkedinSrc from '../images/social-linkedin-32px.svg';
import chinaOceanSrc from '../images/china-ocean.jpg';
import oilfieldsGulfSrc from '../images/oilfields-gulf.jpg';
import chinaChangeSrc from '../images/china-change.jpg';
import loggingFarmSrc from '../images/logging-farm.jpg';
import windfarmOceanSrc from '../images/windfarm-ocean.jpg';
import windfarmInstallSrc from '../images/windfarm-install.jpg';
import loggingWashingtonSrc from '../images/logging-washington.jpg';
import riverDammSrc from '../images/river-damm.jpg';
import solarFarmSrc from '../images/solar-farm.jpg';
import aiDatasetSrc from '../images/ai-dataset.svg';
import brazilSolarSrc from '../images/brazil-solar.jpg';
import indiaWindfarmSrc from '../images/india-windfarm.jpg';
import superResMarineSrc from '../images/super-res-marine.jpg';
import { CenterAlignedDiv } from '../components/utils/sharedStyles';

export const Home = () => {
    const data = [
        {
            description: 'See examples of how our planet is changing',
            details:
                'The monthly geospatial data in Satlas reveals changes in marine infrastructure, renewable energy infrastructure, and tree cover.',
            examples: [
                {
                    src: chinaOceanSrc,
                    alt: 'Offshore wind farms across China',
                    label: 'China invests heavily in offshore wind farms',
                    details: "Since 2017, China's offshore windfarms have grown by over 10x",
                    url: '/map?state={%22view%22:{%22longitude%22:115.14957804430327,%22latitude%22:29.78589362534946,%22zoom%22:4.615318144222101,%22pitch%22:0,%22bearing%22:0},%22showHeatmap%22:true,%22showAnimation%22:false,%22showSatellite%22:false,%22selectedDateOption%22:%222017-01%22,%22selectedDateOptionSecondary%22:%222022-01%22,%22selectedStartDateOption%22:%222017-01%22,%22selectedEndDateOption%22:%222022-01%22,%22selectedTreeCoverFill%22:[{%22label%22:%22None%22,%22isChecked%22:false},{%22label%22:%22Low%22,%22isChecked%22:false},{%22label%22:%22Medium%22,%22isChecked%22:true},{%22label%22:%22High%22,%22isChecked%22:true},{%22label%22:%22Full%22,%22isChecked%22:true}],%22selectedApplication%22:%22Marine%20Infrastructure%22,%22selectedLayout%22:%22Side%20by%20Side%22}',
                },
                {
                    src: windfarmInstallSrc,
                    alt: 'A new windfarm near Denmark',
                    label: 'A new windfarm near Denmark',
                    details:
                        'In recent years, a major offshore windfarm has been developed near Denmark',
                    url: '/map?state=%7B%22view%22:%7B%22longitude%22:7.34471592671585,%22latitude%22:54.813835109212306,%22zoom%22:7.745858277401581,%22pitch%22:0,%22bearing%22:0%7D,%22showHeatmap%22:false,%22showAnimation%22:false,%22showSatellite%22:false,%22selectedDateOption%22:%222017-01%22,%22selectedDateOptionSecondary%22:%222022-01%22,%22selectedStartDateOption%22:%222017-01%22,%22selectedEndDateOption%22:%222022-01%22,%22selectedTreeCoverFill%22:%5B%7B%22label%22:%22None%22,%22isChecked%22:false%7D,%7B%22label%22:%22Low%22,%22isChecked%22:false%7D,%7B%22label%22:%22Medium%22,%22isChecked%22:true%7D,%7B%22label%22:%22High%22,%22isChecked%22:true%7D,%7B%22label%22:%22Full%22,%22isChecked%22:true%7D%5D,%22selectedApplication%22:%22Marine%20Infrastructure%22,%22selectedLayout%22:%22Change%20Over%20Time%22%7D',
                },
                {
                    src: loggingFarmSrc,
                    alt: 'Amazon rainforest deforestation',
                    label: 'Amazon rainforest deforestation',
                    details:
                        'Forests in this region of Brazil were recently converted to agriculture',
                    url: '/map?state={%22view%22:{%22longitude%22:-63.88760455463486,%22latitude%22:-16.540365269741116,%22zoom%22:12.032680909474916,%22pitch%22:0,%22bearing%22:0},%22showHeatmap%22:true,%22showAnimation%22:false,%22showSatellite%22:true,%22selectedDateOption%22:%222016-04%22,%22selectedDateOptionSecondary%22:%222022-12%22,%22selectedStartDateOption%22:%222017-01%22,%22selectedEndDateOption%22:%222022-01%22,%22selectedTreeCoverFill%22:[{%22label%22:%22None%22,%22isChecked%22:false},{%22label%22:%22Low%22,%22isChecked%22:false},{%22label%22:%22Medium%22,%22isChecked%22:true},{%22label%22:%22High%22,%22isChecked%22:true},{%22label%22:%22Full%22,%22isChecked%22:true}],%22selectedApplication%22:%22Tree%20Cover%22,%22selectedLayout%22:%22Side%20by%20Side%22}',
                },
                {
                    src: loggingWashingtonSrc,
                    alt: 'Logging in Washington State',
                    label: 'Logging in Washington State',
                    details:
                        'Satlas reveals tree cover loss from commercial logging in the Olympic Peninsula',
                    url: '/map?state={%22view%22:{%22longitude%22:-124.37448812157066,%22latitude%22:48.03320862466225,%22zoom%22:10.212492495527849,%22pitch%22:0,%22bearing%22:0},%22showHeatmap%22:false,%22showAnimation%22:false,%22showSatellite%22:true,%22selectedDateOption%22:%222016-12%22,%22selectedDateOptionSecondary%22:%222021-12%22,%22selectedStartDateOption%22:%222017-01%22,%22selectedEndDateOption%22:%222022-01%22,%22selectedTreeCoverFill%22:[{%22label%22:%22None%22,%22isChecked%22:false},{%22label%22:%22Low%22,%22isChecked%22:false},{%22label%22:%22Medium%22,%22isChecked%22:true},{%22label%22:%22High%22,%22isChecked%22:true},{%22label%22:%22Full%22,%22isChecked%22:true}],%22selectedApplication%22:%22Tree%20Cover%22,%22selectedLayout%22:%22Change%20Over%20Time%22}',
                },
                {
                    src: windfarmOceanSrc,
                    alt: 'Wind farms off Scotland',
                    label: 'Wind farms off Scotland',
                    details:
                        'A timelapse of the development of the Beatrice and Moray wind farms off of Scotland',
                    url: '/map?state={%22view%22:{%22longitude%22:-2.836918331020911,%22latitude%22:58.20680640225288,%22zoom%22:10.344154475460755,%22pitch%22:0,%22bearing%22:0},%22showHeatmap%22:true,%22showAnimation%22:true,%22showSatellite%22:false,%22selectedDateOption%22:%222018-05%22,%22selectedDateOptionSecondary%22:%222022-09%22,%22selectedStartDateOption%22:%222017-01%22,%22selectedEndDateOption%22:%222022-01%22,%22selectedTreeCoverFill%22:[{%22label%22:%22None%22,%22isChecked%22:false},{%22label%22:%22Low%22,%22isChecked%22:false},{%22label%22:%22Medium%22,%22isChecked%22:true},{%22label%22:%22High%22,%22isChecked%22:true},{%22label%22:%22Full%22,%22isChecked%22:true}],%22selectedApplication%22:%22Marine%20Infrastructure%22,%22selectedLayout%22:%22Single%20Map%22}',
                },
                {
                    src: oilfieldsGulfSrc,
                    alt: 'Oil platforms in the Gulf of Mexico',
                    label: 'Oil platforms in the Gulf of Mexico',
                    details:
                        'There are extensive offshore platforms near Texas, Louisiana, and Mississippi',
                    url: '/map?state={%22view%22:{%22longitude%22:-92.4307567963067,%22latitude%22:28.54215631574708,%22zoom%22:6.247610469849145,%22pitch%22:0,%22bearing%22:0},%22showHeatmap%22:false,%22showAnimation%22:false,%22showSatellite%22:false,%22selectedDateOption%22:%222017-01%22,%22selectedDateOptionSecondary%22:%222022-01%22,%22selectedStartDateOption%22:%222017-01%22,%22selectedEndDateOption%22:%222022-01%22,%22selectedTreeCoverFill%22:[{%22label%22:%22None%22,%22isChecked%22:false},{%22label%22:%22Low%22,%22isChecked%22:false},{%22label%22:%22Medium%22,%22isChecked%22:true},{%22label%22:%22High%22,%22isChecked%22:true},{%22label%22:%22Full%22,%22isChecked%22:true}],%22selectedMarineInfra%22:[{%22label%22:%22Offshore%20Wind%20Turbines%22,%22isChecked%22:false},{%22label%22:%22Offshore%20Platforms%22,%22isChecked%22:true}],%22selectedApplication%22:%22Marine%20Infrastructure%22,%22selectedLayout%22:%22Single%20Map%22}',
                },
                {
                    src: riverDammSrc,
                    alt: 'A new dam in the Amazon',
                    label: 'A new dam in the Amazon',
                    details: 'The Sinop dam was completed along the Teles Pires river in 2019',
                    url: '/map?state={%22view%22:{%22longitude%22:-55.582909664390996,%22latitude%22:-11.391928789409022,%22zoom%22:10.558774146627574,%22pitch%22:0,%22bearing%22:0},%22showHeatmap%22:false,%22showAnimation%22:false,%22showSatellite%22:false,%22selectedDateOption%22:%222016-09%22,%22selectedDateOptionSecondary%22:%222022-10%22,%22selectedStartDateOption%22:%222017-01%22,%22selectedEndDateOption%22:%222022-01%22,%22selectedTreeCoverFill%22:[{%22label%22:%22None%22,%22isChecked%22:false},{%22label%22:%22Low%22,%22isChecked%22:false},{%22label%22:%22Medium%22,%22isChecked%22:true},{%22label%22:%22High%22,%22isChecked%22:true},{%22label%22:%22Full%22,%22isChecked%22:true}],%22selectedApplication%22:%22Tree%20Cover%22,%22selectedLayout%22:%22Change%20Over%20Time%22}',
                },
                {
                    src: solarFarmSrc,
                    alt: 'A new solar farm in Virginia',
                    label: 'A new solar farm in Virginia',
                    details: 'The Pleinmont solar farm is developed in the forests of Virginia',
                    url: '/map?state={%22view%22:{%22longitude%22:-77.78450505464417,%22latitude%22:38.24102030064813,%22zoom%22:13.862586335547446,%22pitch%22:0,%22bearing%22:0},%22showHeatmap%22:true,%22showAnimation%22:false,%22showSatellite%22:true,%22selectedDateOption%22:%222016-07%22,%22selectedDateOptionSecondary%22:%222022-06%22,%22selectedStartDateOption%22:%222017-01%22,%22selectedEndDateOption%22:%222022-01%22,%22selectedTreeCoverFill%22:[{%22label%22:%22None%22,%22isChecked%22:false},{%22label%22:%22Low%22,%22isChecked%22:false},{%22label%22:%22Medium%22,%22isChecked%22:true},{%22label%22:%22High%22,%22isChecked%22:true},{%22label%22:%22Full%22,%22isChecked%22:true}],%22selectedApplication%22:%22Tree%20Cover%22,%22selectedLayout%22:%22Side%20by%20Side%22}',
                },
                {
                    src: chinaChangeSrc,
                    alt: 'Wind farm near Shanghai',
                    label: 'Wind farm near Shanghai',
                    details:
                        'In recent years, China has developed a major offshore wind farm near Shanghai',
                    url: '/map?state={%22view%22:{%22longitude%22:121.85643660939843,%22latitude%22:31.663586218729293,%22zoom%22:7.135557594660272,%22pitch%22:0,%22bearing%22:0},%22showHeatmap%22:false,%22showAnimation%22:false,%22showSatellite%22:false,%22selectedDateOption%22:%222022-12%22,%22selectedDateOptionSecondary%22:%222022-01%22,%22selectedStartDateOption%22:%222017-01%22,%22selectedEndDateOption%22:%222022-01%22,%22selectedTreeCoverFill%22:[{%22label%22:%22None%22,%22isChecked%22:false},{%22label%22:%22Low%22,%22isChecked%22:false},{%22label%22:%22Medium%22,%22isChecked%22:true},{%22label%22:%22High%22,%22isChecked%22:true},{%22label%22:%22Full%22,%22isChecked%22:true}],%22selectedApplication%22:%22Marine%20Infrastructure%22,%22selectedLayout%22:%22Change%20Over%20Time%22}',
                },
                {
                    src: brazilSolarSrc,
                    alt: 'Solar development in Brazil',
                    label: 'Solar development in Brazil',
                    details: 'Brazil has invested heavily in solar farms over the past few years',
                    url: '/map?state={%22view%22:{%22longitude%22:-47.924356069985436,%22latitude%22:-16.609675526604832,%22zoom%22:4.416484721877065,%22pitch%22:0,%22bearing%22:0},%22showHeatmap%22:true,%22showAnimation%22:false,%22showSatellite%22:false,%22selectedDateOption%22:%222022-10%22,%22selectedDateOptionSecondary%22:%222016-08%22,%22selectedStartDateOption%22:%222016-01%22,%22selectedEndDateOption%22:%222022-12%22,%22selectedTreeCoverFill%22:[{%22label%22:%22None%22,%22isChecked%22:false},{%22label%22:%22Low%22,%22isChecked%22:false},{%22label%22:%22Medium%22,%22isChecked%22:true},{%22label%22:%22High%22,%22isChecked%22:true},{%22label%22:%22Full%22,%22isChecked%22:true}],%22selectedMarineInfra%22:[{%22label%22:%22Offshore%20Wind%20Turbines%22,%22isChecked%22:true},{%22label%22:%22Offshore%20Platforms%22,%22isChecked%22:false}],%22selectedRenewableEnergy%22:[{%22label%22:%22Solar%20Farms%22,%22isChecked%22:true},{%22label%22:%22Onshore%20Wind%20Turbines%22,%22isChecked%22:true}],%22selectedApplication%22:%22Renewable%20Energy%22,%22selectedLayout%22:%22Side%20by%20Side%22}',
                },
                {
                    src: indiaWindfarmSrc,
                    alt: 'Windfarm development in southern India',
                    label: 'Windfarm development in southern India',
                    details: 'Many windmills have been developed recently in southern India',
                    url: '/map?state={%22view%22:{%22longitude%22:77.54333804437726,%22latitude%22:8.739065569174855,%22zoom%22:8.817829347858119,%22pitch%22:0,%22bearing%22:0},%22showHeatmap%22:false,%22showAnimation%22:false,%22showSatellite%22:false,%22selectedDateOption%22:%222022-10%22,%22selectedDateOptionSecondary%22:%222016-08%22,%22selectedStartDateOption%22:%222016-07%22,%22selectedEndDateOption%22:%222022-12%22,%22selectedTreeCoverFill%22:[{%22label%22:%22None%22,%22isChecked%22:false},{%22label%22:%22Low%22,%22isChecked%22:false},{%22label%22:%22Medium%22,%22isChecked%22:true},{%22label%22:%22High%22,%22isChecked%22:true},{%22label%22:%22Full%22,%22isChecked%22:true}],%22selectedMarineInfra%22:[{%22label%22:%22Offshore%20Wind%20Turbines%22,%22isChecked%22:true},{%22label%22:%22Offshore%20Platforms%22,%22isChecked%22:false}],%22selectedRenewableEnergy%22:[{%22label%22:%22Solar%20Farms%22,%22isChecked%22:false},{%22label%22:%22Onshore%20Wind%20Turbines%22,%22isChecked%22:true}],%22selectedApplication%22:%22Renewable%20Energy%22,%22selectedLayout%22:%22Change%20Over%20Time%22}',
                },
                {
                    src: superResMarineSrc,
                    alt: 'Super-resolution of a marina',
                    label: 'Super-resolution of a marina',
                    details: 'Super-resolution highlights the details of the Everett waterfront',
                    url: '/map?state={%22view%22:{%22longitude%22:-122.22242118181879,%22latitude%22:47.99824713716666,%22zoom%22:15.010202332643606,%22pitch%22:0,%22bearing%22:0},%22showHeatmap%22:false,%22showAnimation%22:false,%22showSatellite%22:false,%22selectedDateOption%22:%222022-10%22,%22selectedDateOptionSecondary%22:%222016-08%22,%22selectedStartDateOption%22:%222016-07%22,%22selectedEndDateOption%22:%222022-12%22,%22selectedTreeCoverFill%22:[{%22label%22:%22None%22,%22isChecked%22:false},{%22label%22:%22Low%22,%22isChecked%22:false},{%22label%22:%22Medium%22,%22isChecked%22:true},{%22label%22:%22High%22,%22isChecked%22:true},{%22label%22:%22Full%22,%22isChecked%22:true}],%22selectedMarineInfra%22:[{%22label%22:%22Offshore%20Wind%20Turbines%22,%22isChecked%22:true},{%22label%22:%22Offshore%20Platforms%22,%22isChecked%22:false}],%22selectedRenewableEnergy%22:[{%22label%22:%22Solar%20Farms%22,%22isChecked%22:false},{%22label%22:%22Onshore%20Wind%20Turbines%22,%22isChecked%22:true}],%22selectedApplication%22:%22Super%20Resolution%22,%22selectedLayout%22:%22Single%20Map%22}',
                },
            ],
        },
    ];

    return (
        <>
            <SatlasHero exploreUrl="/map" />
            <MainContainer>
                <StackableSection>
                    <MaxWidthText>
                        {data.map((u) => (
                            <UsageArea key={u.description}>
                                <h3>{u.description}</h3>
                                <Typography sx={{ mb: 2 }}>{u.details}</Typography>
                                <Usage>
                                    {u.examples.map((ex) => (
                                        <Card key={ex.label} sx={{ maxWidth: 284 }}>
                                            <CardActionArea href={ex.url}>
                                                <CardMedia
                                                    component="img"
                                                    height="140"
                                                    image={ex.src}
                                                    alt={ex.alt}
                                                />
                                                <CardContent>
                                                    <Typography
                                                        gutterBottom
                                                        variant="h5"
                                                        component="div">
                                                        {ex.label}
                                                    </Typography>
                                                    <Typography
                                                        variant="body2"
                                                        color="text.secondary">
                                                        {ex.details}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    ))}
                                </Usage>
                            </UsageArea>
                        ))}
                    </MaxWidthText>
                </StackableSection>

                <StackableGreySection>
                    <MaxWidthText>
                        <h3>Super-Resolution</h3>
                        <Grid container columnSpacing={7} rowSpacing={2}>
                            <Grid item sm={12} md={6}>
                                <Typography>
                                    Discover how our AI models can enhance low resolution satellite
                                    imagery to produce high resolution images on a global scale.
                                </Typography>
                                <ButtonWithHeadSpace
                                    href="/superres"
                                    variant="contained"
                                    size="large">
                                    Explore Super-Resolution
                                </ButtonWithHeadSpace>
                            </Grid>
                            <Grid item sm={12} md={6}>
                                <SuperResSlideViewer />
                            </Grid>
                        </Grid>
                    </MaxWidthText>
                </StackableGreySection>

                <StackableSection>
                    <MaxWidthText>
                        <h3>AI Models</h3>
                        <Typography paragraph>
                            AI models in Satlas employ state of the art architectures and training
                            algorithms in computer vision. These models leverage a new large-scale
                            remote sensing dataset called SatlasPretrain with over 30 TB of imagery
                            and 300 million labels.
                        </Typography>
                        <FullWidthImageWithAttribution>
                            <img
                                src={aiDatasetSrc}
                                alt="Examples of the diverse satellite images and labels in SatlasPretrain"
                            />
                        </FullWidthImageWithAttribution>
                        <ButtonWithHeadSpace href="/ai" variant="contained" size="large">
                            Learn More about AI in Satlas
                        </ButtonWithHeadSpace>
                    </MaxWidthText>
                </StackableSection>

                <StackableGreySection>
                    <MaxWidthText>
                        <h3>Geospatial Data</h3>
                        <Typography>
                            Our AI-generated geospatial datasets are freely and publicly available
                            and can be downloaded for offline analysis. We release our AI models and
                            training labels as well.
                        </Typography>
                        <ButtonWithHeadSpace href="/data" variant="contained" size="large">
                            Access Data
                        </ButtonWithHeadSpace>
                    </MaxWidthText>
                </StackableGreySection>

                <StackableSection>
                    <MaxWidthText>
                        <h3>Team</h3>
                        <TeamContent>
                            <a href="https://prior.allenai.org/">
                                <LogoPrior src={priorSrc} alt="PRIOR" />
                            </a>
                            <Typography variant="body2">
                                This demo page is built and maintained by{' '}
                                <a href="https://prior.allenai.org/">PRIOR</a> and colleagues at
                                the&nbsp;
                                <a href="http://allenai.org">Allen Institute for AI</a>. Our team
                                seeks to advance computer vision to create AI systems that see,
                                explore, learn, and reason about the world.
                            </Typography>
                        </TeamContent>
                        <Credits />
                    </MaxWidthText>
                    <MaxWidthText>
                        <ShareArea>
                            <Typography>Share this project:</Typography>
                            <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fsatlas-explorer.apps.allenai.org%2F">
                                <img src={shareFacebookSrc} alt="Share on Facebook" />
                            </a>
                            <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fsatlas-explorer.apps.allenai.org%2F&text=Satlas%20Explorer%20is%20a%20platform%20for%20visualizing%20changes%20across%20the%20globe%20by%20using%20AI%20models%20to%20analyze%20satellite%20imagery.">
                                <img src={shareTwitterSrc} alt="Share on Twitter" />
                            </a>
                            <a href="http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fsatlas-explorer.apps.allenai.org%2F&title=Satlas%20Explorer&summary=Satlas%20Explorer%20is%20a%20platform%20for%20visualizing%20changes%20across%20the%20globe%20by%20using%20AI%20models%20to%20analyze%20satellite%20imagery.">
                                <img src={shareLinkedinSrc} alt="Share on Linkedin" />
                            </a>
                        </ShareArea>
                    </MaxWidthText>
                </StackableSection>
                <CenterAlignedDiv>
                    <Footer />
                </CenterAlignedDiv>
            </MainContainer>
        </>
    );
};

const UsageArea = styled.div`
    margin-top: ${({ theme }) => theme.spacing(4)};
    margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const Usage = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing(3)};
    justify-content: center;
`;

const ShareArea = styled.div`
    display: grid;
    grid-template-columns: repeat(4, auto) 1fr;
    gap: ${({ theme }) => theme.spacing(1)};
    align-items: center;
    margin-top: ${({ theme }) => theme.spacing(5)};
`;

const TeamContent = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(2)};

    ${({ theme }) => theme.breakpoints.down('md')} {
        grid-template-columns: 1fr;
    }
`;

const LogoPrior = styled.img`
    width: 133px;
    max-width: 100%;
`;

const ButtonWithHeadSpace = styled(Button)`
    && {
        margin-top: ${({ theme }) => theme.spacing(2)};
    }
`;
