import { color2 } from '@allenai/varnish2/theme';

import { FeatureCategories } from '../../data/layers';
import { RenewableEnergyLabels } from '../RenewableEnergySelector';

export const getRenewableEnergyColors = (renewableEnergyLabel: string) => {
    switch (renewableEnergyLabel) {
        case RenewableEnergyLabels.SolarFarms:
        case FeatureCategories.SolarFarms:
            return color2.O3.hex;
        case RenewableEnergyLabels.OnshoreWindTurbines:
        case FeatureCategories.OnshoreWindTurbines:
        default:
            return color2.P3.hex;
    }
};

export const getRenewableEnergyUnit = (renewableEnergyLabel?: string) => {
    switch (renewableEnergyLabel) {
        case RenewableEnergyLabels.SolarFarms:
        case FeatureCategories.SolarFarms:
            return 'km\u00B2';
        case RenewableEnergyLabels.OnshoreWindTurbines:
        case FeatureCategories.OnshoreWindTurbines:
        default:
            return '';
    }
};

export const renewableEnergyLabelColor = color2.N1.hex;
