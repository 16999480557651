import React, { useCallback } from 'react';
import styled from 'styled-components';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import Tooltip from '@mui/material/Tooltip';

interface Props<T> {
    options: T[];
    value: T;
    onChange: (value: T) => void;
    className?: string;
    getTooltip?: (value: T) => string | null;
}

export const RadioSelector = <T extends string>({
    options,
    value,
    onChange,
    className,
    getTooltip,
}: Props<T>) => {
    const onChangePassThrough = useCallback(
        (evt) => {
            onChange(evt.target.value as T);
        },
        [onChange]
    );

    return (
        <RadioGroup className={className} value={value} onChange={onChangePassThrough}>
            {options.map((opt) =>
                getTooltip !== undefined && getTooltip(opt) !== null ? (
                    <Tooltip key={'tooltip_' + opt} placement="right-start" title={getTooltip(opt)}>
                        <NarrowFormControlLabel
                            key={opt}
                            value={opt}
                            control={<Radio />}
                            label={opt}
                        />
                    </Tooltip>
                ) : (
                    <NarrowFormControlLabel key={opt} value={opt} control={<Radio />} label={opt} />
                )
            )}
        </RadioGroup>
    );
};

const NarrowFormControlLabel = styled(FormControlLabel)`
    &&& {
        height: 30px;
        .MuiFormControlLabel-label {
            line-height: 1;
        }
    }
`;
