import React, { useContext } from 'react';

import { Typography } from '@mui/material';

import { MapOptionsContext } from '../context/MapOptionsContext';
import { CheckboxSelector, CheckboxType } from './baseSelectors/CheckboxSelector';

export enum RenewableEnergyLabels {
    SolarFarms = 'Solar Farms',
    OnshoreWindTurbines = 'Onshore Wind Turbines',
}

const options: CheckboxType[] = [
    { label: RenewableEnergyLabels.SolarFarms, isChecked: true },
    { label: RenewableEnergyLabels.OnshoreWindTurbines, isChecked: true },
];

export type RenewableEnergyOption = typeof options;
export const defaultValue = options;

interface Props {}

export const RenewableEnergySelector = ({ ...rest }: Props) => {
    const { selectedRenewableEnergy, setSelectedRenewableEnergy } = useContext(MapOptionsContext);

    return (
        <>
            <Typography>Renewable Energy:</Typography>
            <CheckboxSelector
                options={selectedRenewableEnergy}
                setActiveOptions={setSelectedRenewableEnergy}
                {...rest}
            />
        </>
    );
};
