import React, { useContext } from 'react';

import { MapOptionsContext } from '../context/MapOptionsContext';
import { SwitchSelector } from './baseSelectors/SwitchSelector';

export const defaultValue = false;

interface Props {}

export const AnimationSelector = ({ ...rest }: Props) => {
    const { showAnimation, setShowAnimation } = useContext(MapOptionsContext);
    return (
        <SwitchSelector
            label="View Timelapse"
            value={showAnimation}
            onChange={setShowAnimation}
            {...rest}
        />
    );
};
