import styled from 'styled-components';

import bannerSrc from '../images/super-res/super-res-banner.jpg';

export const SubPageHero = styled.div`
    background: #0b0e17 url(${bannerSrc}) center top no-repeat;
    padding: ${({ theme }) => `${theme.spacing(16)} ${theme.spacing(4)}`};
    text-align: center;

    h1 {
        color: white;
        margin: 0;
        font-weight: normal;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
        padding: ${({ theme }) => `${theme.spacing(6)} ${theme.spacing(4)}`};
    }
`;
