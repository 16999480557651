import React, { useContext } from 'react';

import { Typography } from '@mui/material';

import { MapOptionsContext } from '../context/MapOptionsContext';
import { CheckboxSelector, CheckboxType } from './baseSelectors/CheckboxSelector';

export enum MarineInfraLabels {
    OffshoreTurbines = 'Offshore Wind Turbines',
    OffshorePlatforms = 'Offshore Platforms',
}

const options: CheckboxType[] = [
    { label: MarineInfraLabels.OffshoreTurbines, isChecked: true },
    { label: MarineInfraLabels.OffshorePlatforms, isChecked: false },
];

export type MarineInfraOption = typeof options;
export const defaultValue = options;

interface Props {}

export const MarineInfraSelector = ({ ...rest }: Props) => {
    const { selectedMarineInfra, setSelectedMarineInfra } = useContext(MapOptionsContext);

    return (
        <>
            <Typography>Marine Infrastructure:</Typography>
            <CheckboxSelector
                options={selectedMarineInfra}
                setActiveOptions={setSelectedMarineInfra}
                {...rest}
            />
        </>
    );
};
