import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Typography, Stack } from '@mui/material';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Map as mapboxGlMap } from 'mapbox-gl';

import { Map as MapGl } from '../components/Map';
import { MapOptionsContext } from '../context/MapOptionsContext';
import { LatLong } from '../components/LatLong';
import { AccordionDrawer } from '../components/AccordionDrawer';
import { FeatureData, FeatureDetails } from '../components/details/FeatureDetails';
import { LayoutOption } from '../components/LayoutSelector';
import { ApplicationOption } from '../components/ApplicationSelector';
import { ApplicationConfig } from '../config/applicationConfig';
import { AddedKey, RemovedKey, UnchangedKey } from '../components/utils/changeOverTime';
import { LabeledDictionary } from '../components/utils/base';
import {
    addedTreeCoverFilter,
    getAddedTimeFilter,
    getRemovedTimeFilter,
    getUnchangedTimeFilter,
    removedTreeCoverFilter,
    unchangedTreeCoverFilter,
} from '../components/utils/filters';
import { OptionsAsAccordion, ToolsArea } from '../config/uiOptionsConfig';
import { MarineInfraLabels } from '../components/MarineInfraSelector';
import { TreeCoverFillLabels } from '../components/TreeCoverFillSelector';
import { RenewableEnergyLabels } from '../components/RenewableEnergySelector';
import { Search } from '../components/Search';
import { headerOffset } from '../components/utils/sharedStyles';

export const Map = () => {
    const {
        selectedLayout,
        selectedApplication,
        selectedStartDateOption,
        selectedEndDateOption,
        resetMap,
        selectedMarineInfra,
        selectedRenewableEnergy,
        selectedTreeCoverFill,
    } = useContext(MapOptionsContext);

    const location = useLocation();
    const [searchParams] = useSearchParams();
    useEffect(() => {
        const state = searchParams.get('state');
        if (state) {
            resetMap(JSON.parse(state));
        }
    }, [location]);

    const onMapData = (map: mapboxGlMap): FeatureData => {
        if (!map) {
            return {};
        }
        if (selectedLayout === LayoutOption.ChangeOverTime) {
            let addedFilter: any[] = [];
            let removedFilter: any[] = [];
            let unchangedFilter: any[] = [];
            if (selectedApplication !== ApplicationOption.TreeCover) {
                addedFilter = getAddedTimeFilter(selectedStartDateOption, selectedEndDateOption);

                removedFilter = getRemovedTimeFilter(
                    selectedStartDateOption,
                    selectedEndDateOption
                );

                unchangedFilter = getUnchangedTimeFilter(
                    selectedStartDateOption,
                    selectedEndDateOption
                );
            } else {
                addedFilter = addedTreeCoverFilter;
                removedFilter = removedTreeCoverFilter;
                unchangedFilter = unchangedTreeCoverFilter;
            }

            const dataAdded = map.queryRenderedFeatures(undefined, {
                filter: addedFilter,
            });

            const dataRemoved = map.queryRenderedFeatures(undefined, {
                filter: removedFilter,
            });

            const dataUnchanged = map.queryRenderedFeatures(undefined, {
                filter: unchangedFilter,
            });

            const changeDetails: LabeledDictionary<number> = {
                label: selectedApplication,
                unit: ApplicationConfig[selectedApplication].changeOverTimeUnitFunc(),
                data: { [AddedKey]: 0, [RemovedKey]: 0, [UnchangedKey]: 0 },
            };

            const changeOverTimeValueFunc =
                ApplicationConfig[selectedApplication].changeOverTimeValueFunc;

            if (changeOverTimeValueFunc) {
                dataAdded.forEach((d) => {
                    if (d && d.properties) {
                        changeDetails.data[AddedKey] += changeOverTimeValueFunc(d);
                    }
                });
                dataRemoved.forEach((d) => {
                    if (d && d.properties) {
                        changeDetails.data[RemovedKey] += changeOverTimeValueFunc(d);
                    }
                });
                dataUnchanged.forEach((d) => {
                    if (d && d.properties) {
                        changeDetails.data[UnchangedKey] += changeOverTimeValueFunc(d);
                    }
                });
            }
            return {
                details: [changeDetails],
                layout: selectedLayout,
                application: selectedApplication,
            };
        }

        const activeMarineFeatures: Array<MarineInfraLabels> = [];
        selectedMarineInfra.map(
            (infra) =>
                infra.isChecked && activeMarineFeatures.push(infra.label as MarineInfraLabels)
        );

        const activeRenewableEnergyFeatures: Array<RenewableEnergyLabels> = [];
        selectedRenewableEnergy.map(
            (v) =>
                v.isChecked && activeRenewableEnergyFeatures.push(v.label as RenewableEnergyLabels)
        );

        const activeTreeCoverFills: Array<TreeCoverFillLabels> = [];
        selectedTreeCoverFill.map(
            (fill) => fill.isChecked && activeTreeCoverFills.push(fill.label as TreeCoverFillLabels)
        );

        const getFeatureData = ApplicationConfig[selectedApplication].getFeatureData;
        if (getFeatureData) {
            return {
                ...getFeatureData(map, [
                    ...activeMarineFeatures,
                    ...activeRenewableEnergyFeatures,
                    ...activeTreeCoverFills,
                ]),
                layout: selectedLayout,
                application: selectedApplication,
            };
        }

        return {
            layout: selectedLayout,
            application: selectedApplication,
        };
    };

    const twoColumnView =
        selectedLayout !== LayoutOption.Single && selectedLayout !== LayoutOption.ChangeOverTime;

    return (
        <>
            <SearchContainer>
                <FullLengthSearch />
            </SearchContainer>
            <OuterContainer>
                <MapsGrid showTwoColumnLayout={twoColumnView}>
                    <Map1Area>
                        <MapGl getFeatureData={(map) => onMapData(map)}></MapGl>
                    </Map1Area>
                    {twoColumnView ? (
                        <Map2Area>
                            <MapGl
                                getFeatureData={(map) => onMapData(map)}
                                isSecondary={true}></MapGl>
                        </Map2Area>
                    ) : null}
                </MapsGrid>

                <OptionsAsAccordion />

                <DetailsArea spacing={1}>
                    <AccordionDrawer header={<Typography>Details</Typography>}>
                        <Stack spacing={1}>
                            <LatLong />
                            <FeatureDetails />
                        </Stack>
                    </AccordionDrawer>
                </DetailsArea>
            </OuterContainer>
        </>
    );
};

const SearchContainer = styled.div`
    padding: ${({ theme }) => theme.spacing(2)};
`;

const FullLengthSearch = styled(Search)`
    min-width: 100%;
`;

const OuterContainer = styled.div`
    position: relative;
`;

const Map1Area = styled.div`
    border-left: ${({ theme }) => `2px solid ${theme.color2.B5}`};
`;

const Map2Area = styled.div``;

const MapsGrid = styled.div<{ showTwoColumnLayout: boolean }>`
    display: grid;
    overflow: hidden;
    grid-template-columns: 1fr ${({ showTwoColumnLayout }) => (showTwoColumnLayout ? '1fr' : '0px')};
    height: calc(100vh - ${headerOffset});
    width: 100%;

    ${Map2Area} {
        border-left: ${({ showTwoColumnLayout, theme }) =>
            showTwoColumnLayout ? `2px solid ${theme.color2.B5}` : null};
    }
`;

const DetailsArea = styled(ToolsArea)`
    right: ${({ theme }) => theme.spacing(1)};
    left: unset;
    direction: ltr;

    ${({ theme }) => theme.breakpoints.down('sm')} {
        width: calc(100% - ${({ theme }) => theme.spacing(2)});
    }
`;
