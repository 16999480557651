import React from 'react';
import { Table, TableHead, TableRow, TableBody } from '@mui/material';

import { NarrowTableCell, getFormattedValue } from './FeatureDetails';
import {
    AddedKey,
    RemovedKey,
    UnchangedKey,
    getChangeOverTimeColors,
    getChangeOverTimeLabelColor,
} from '../utils/changeOverTime';
import { LabeledDictionary } from '../utils/base';
import { FeatureChip } from '../FeatureChip';

export interface ChangeData {
    label: string;
    unit: string;
    added: number;
    removed: number;
    unchanged: number;
}

export interface Props {
    details?: LabeledDictionary<number>[];
    className?: string;
}

export const ChangeOverTimeDetails = ({ className, details }: Props) => {
    const rows: ChangeData[] = (details || []).map((v) => {
        return {
            ...v,
            added: v.data[AddedKey],
            removed: v.data[RemovedKey],
            unchanged: v.data[UnchangedKey],
        };
    });

    const chipWidth = '90px';

    return (
        <Table className={className} size="small">
            <TableHead>
                <TableRow>
                    <NarrowTableCell colSpan={2}>
                        <strong>Changes over time</strong>
                    </NarrowTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((row) => (
                        <React.Fragment key={row.label}>
                            <TableRow key={row.label}>
                                <NarrowTableCell>{row.label}</NarrowTableCell>
                            </TableRow>
                            <TableRow key={row.label + 'added'}>
                                <NarrowTableCell>
                                    <FeatureChip
                                        bgcolor={getChangeOverTimeColors(AddedKey)}
                                        textColor={getChangeOverTimeLabelColor(AddedKey)}
                                        width={chipWidth}
                                        label="Added"
                                    />
                                </NarrowTableCell>
                                <NarrowTableCell align="right">
                                    {getFormattedValue(row.added, row.unit)}
                                </NarrowTableCell>
                            </TableRow>
                            <TableRow key={row.label + 'removed'}>
                                <NarrowTableCell>
                                    <FeatureChip
                                        bgcolor={getChangeOverTimeColors(RemovedKey)}
                                        textColor={getChangeOverTimeLabelColor(RemovedKey)}
                                        width={chipWidth}
                                        label="Removed"
                                    />
                                </NarrowTableCell>
                                <NarrowTableCell align="right">
                                    {getFormattedValue(row.removed, row.unit)}
                                </NarrowTableCell>
                            </TableRow>
                            <TableRow key={row.label + 'unchanged'}>
                                <NarrowTableCell>
                                    <FeatureChip
                                        bgcolor={getChangeOverTimeColors(UnchangedKey)}
                                        textColor={getChangeOverTimeLabelColor(UnchangedKey)}
                                        width={chipWidth}
                                        label="Unchanged"
                                    />
                                </NarrowTableCell>
                                <NarrowTableCell align="right">
                                    {getFormattedValue(row.unchanged, row.unit)}
                                </NarrowTableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
            </TableBody>
        </Table>
    );
};
