import React, { useContext } from 'react';
import { Map } from 'mapbox-gl';
import { Dictionary } from '@allenai/varnish2/utils/base';

import { MapSources } from '../data/sources';
import { FeatureCategories } from '../data/layers';
import { ApplicationOption } from '../components/ApplicationSelector';
import { TreeCoverFillSelector, TreeCoverFillLabels } from '../components/TreeCoverFillSelector';
import { HeatSelector } from '../components/HeatSelector';
import { FeatureData } from '../components/details/FeatureDetails';
import { MapOptionsContext } from '../context/MapOptionsContext';
import { LayoutOption } from '../components/LayoutSelector';
import { LabeledDictionary } from '../components/utils/base';
import {
    FullKey,
    HighKey,
    LowKey,
    MediumKey,
    NoneKey,
    getTreeCoverColors,
    getTreeCoverLabelColor,
    getTreeCoverOrder,
    getTreeCoverUnit,
} from '../components/utils/treeCover';
import { MarineInfraLabels, MarineInfraSelector } from '../components/MarineInfraSelector';
import {
    RenewableEnergyLabels,
    RenewableEnergySelector,
} from '../components/RenewableEnergySelector';
import {
    getRenewableEnergyColors,
    getRenewableEnergyUnit,
    renewableEnergyLabelColor,
} from '../components/utils/renewableEnergy';
import {
    getMarineInfraColors,
    getMarineInfraUnit,
    marineInfraLabelColor,
} from '../components/utils/marineInfra';
import { AnnotationSelector } from '../components/AnnotationSelector';
import { SatelliteSelector } from '../components/SatelliteSelector';
import { SuperResolutionSelector } from '../components/SuperResolutionSelector';
import { DetailsTable } from '../components/details/DetailsTable';
import { FeatureChip } from '../components/FeatureChip';

type EnumDictionary<T extends string, U> = {
    [K in T]: U;
};

interface ApplicationConfigData {
    Options: () => JSX.Element | null;
    Details: () => JSX.Element | null;

    getFeatureData?:
        | ((
              map: Map,
              activeFeatures?: Array<
                  MarineInfraLabels | RenewableEnergyLabels | TreeCoverFillLabels
              >
          ) => FeatureData)
        | null;
    layoutOptions?: LayoutOption[];
    changeOverTimeUnitFunc: (cat?: string) => string;
    changeOverTimeValueFunc?: ((data: Dictionary<any>) => number) | null;
    TimeDisplayOverride?: () => JSX.Element | null; // if undefined, default TimeDisplayOverride is the Timeline
}

export const ApplicationConfig: EnumDictionary<ApplicationOption, ApplicationConfigData> = {
    [ApplicationOption.SuperResolution]: {
        Options: () => {
            return (
                <>
                    <SuperResolutionSelector />
                    <SatelliteSelector />
                </>
            );
        },
        Details: () => {
            return null;
        },
        layoutOptions: [LayoutOption.Single],
        TimeDisplayOverride: () => {
            return <>February 2023 - June 2023</>; // This will change as we get more super res times
        },
        changeOverTimeUnitFunc: () => '',
    },
    [ApplicationOption.MarineInfra]: {
        Options: () => {
            const { selectedLayout } = useContext(MapOptionsContext);
            return (
                <>
                    <SatelliteSelector />
                    <AnnotationSelector />
                    <MarineInfraSelector />
                    {selectedLayout !== LayoutOption.ChangeOverTime ? <HeatSelector /> : null}
                </>
            );
        },
        Details: () => {
            const {
                featureDetails,
                featureDetailsSecondary,
                selectedDateOption,
                selectedDateOptionSecondary,
                selectedLayout,
            } = useContext(MapOptionsContext);
            const data = featureDetails.details?.map((v, i) => {
                return {
                    ...v,
                    value: v.data.value,
                    valueSecondary: featureDetailsSecondary.details
                        ? featureDetailsSecondary.details[i]?.data?.value
                        : undefined,
                };
            });
            return (
                <DetailsTable
                    date={selectedDateOption}
                    dateSecondary={selectedDateOptionSecondary}
                    data={data}
                    isSingle={selectedLayout === LayoutOption.Single}
                    title="Offshore Infrastructure"
                    getChip={(label: string) => (
                        <FeatureChip
                            bgcolor={getMarineInfraColors(label)}
                            textColor={marineInfraLabelColor}
                            label={label.split('Offshore ')[1]}
                        />
                    )}
                />
            );
        },
        getFeatureData: (
            map: Map,
            activeFeatures?: Array<MarineInfraLabels | RenewableEnergyLabels | TreeCoverFillLabels>
        ): FeatureData => {
            const windTurbineFilter = [
                '==',
                ['get', 'category'],
                FeatureCategories.OffshoreTurbines,
            ];
            const offshorePlatFilter = [
                '==',
                ['get', 'category'],
                FeatureCategories.OffshorePlatforms,
            ];
            if (
                map &&
                map.getSource(MapSources.HistoryMarineInfra) &&
                map.isSourceLoaded(MapSources.HistoryMarineInfra)
            ) {
                let windTurbinesOn = false;
                let platformsOn = false;

                if (activeFeatures) {
                    activeFeatures.forEach((feature) => {
                        if (feature === MarineInfraLabels.OffshorePlatforms) {
                            platformsOn = true;
                        }
                        if (feature === MarineInfraLabels.OffshoreTurbines) {
                            windTurbinesOn = true;
                        }
                    });
                }
                const dataWindTurbine = map.queryRenderedFeatures(undefined, {
                    filter: windTurbineFilter,
                });
                const dataPlatform = map.queryRenderedFeatures(undefined, {
                    filter: offshorePlatFilter,
                });
                const windTurbineDetails: LabeledDictionary<number> = {
                    label: MarineInfraLabels.OffshoreTurbines,
                    unit: getMarineInfraUnit(MarineInfraLabels.OffshoreTurbines),
                    data: { value: 0 },
                };
                dataWindTurbine.forEach((d) => {
                    if (d && d.properties) {
                        const count = d.properties.point_count || 1;
                        windTurbineDetails.data.value += count;
                    }
                });
                const platformDetails: LabeledDictionary<number> = {
                    label: MarineInfraLabels.OffshorePlatforms,
                    unit: getMarineInfraUnit(MarineInfraLabels.OffshorePlatforms),
                    data: { value: 0 },
                };
                dataPlatform.forEach((d) => {
                    if (d && d.properties) {
                        const count = d.properties.point_count || 1;
                        platformDetails.data.value += count;
                    }
                });

                const detailsArr = [];
                windTurbinesOn && detailsArr.push(windTurbineDetails);
                platformsOn && detailsArr.push(platformDetails);
                return { details: detailsArr };
            }
            return {};
        },
        changeOverTimeUnitFunc: getMarineInfraUnit,
        changeOverTimeValueFunc: (data: Dictionary<any>) => {
            if (data && data.properties) {
                return data.properties.point_count || 1;
            } else {
                return 0;
            }
        },
    },

    [ApplicationOption.RenewableEnergy]: {
        Options: () => {
            const { selectedLayout } = useContext(MapOptionsContext);
            return (
                <>
                    <SatelliteSelector />
                    <AnnotationSelector />
                    <RenewableEnergySelector />
                    {selectedLayout !== LayoutOption.ChangeOverTime ? <HeatSelector /> : null}
                </>
            );
        },
        Details: () => {
            const {
                featureDetails,
                featureDetailsSecondary,
                selectedDateOption,
                selectedDateOptionSecondary,
                selectedLayout,
            } = useContext(MapOptionsContext);
            const data = featureDetails.details?.map((v, i) => {
                return {
                    ...v,
                    value: v.data.value,
                    valueSecondary: featureDetailsSecondary.details
                        ? featureDetailsSecondary.details[i]?.data?.value
                        : undefined,
                };
            });
            return (
                <DetailsTable
                    date={selectedDateOption}
                    dateSecondary={selectedDateOptionSecondary}
                    data={data}
                    isSingle={selectedLayout === LayoutOption.Single}
                    title="Renewable Energy"
                    getChip={(label: string) => (
                        <FeatureChip
                            bgcolor={getRenewableEnergyColors(label)}
                            textColor={renewableEnergyLabelColor}
                            label={
                                label.startsWith('Onshore ')
                                    ? label.slice('Onshore '.length)
                                    : label
                            }
                        />
                    )}
                />
            );
        },
        getFeatureData: (
            map: Map,
            activeFeatures?: Array<MarineInfraLabels | RenewableEnergyLabels | TreeCoverFillLabels>
        ): FeatureData => {
            const solarFarmFilter = ['==', ['get', 'category'], FeatureCategories.SolarFarms];
            const onshoreWindTurbineFilter = [
                '==',
                ['get', 'category'],
                FeatureCategories.OnshoreWindTurbines,
            ];
            if (
                map &&
                map.getSource(MapSources.HistoryRenewableEnergy) &&
                map.isSourceLoaded(MapSources.HistoryRenewableEnergy)
            ) {
                let onshoreWindTurbinesOn = false;
                let solarFarmsOn = false;
                if (activeFeatures) {
                    activeFeatures.forEach((feature) => {
                        if (feature === RenewableEnergyLabels.SolarFarms) {
                            solarFarmsOn = true;
                        }
                        if (feature === RenewableEnergyLabels.OnshoreWindTurbines) {
                            onshoreWindTurbinesOn = true;
                        }
                    });
                }
                const dataSolarFarm = map.queryRenderedFeatures(undefined, {
                    filter: solarFarmFilter,
                });
                const dataOnshoreWindTurbine = map.queryRenderedFeatures(undefined, {
                    filter: onshoreWindTurbineFilter,
                });
                const solarFarmDetails: LabeledDictionary<number> = {
                    label: RenewableEnergyLabels.SolarFarms,
                    unit: getRenewableEnergyUnit(RenewableEnergyLabels.SolarFarms),
                    data: { value: 0 },
                };
                dataSolarFarm.forEach((d) => {
                    if (d && d.properties && d.properties.point === true) {
                        // the details are not calculated using any of the line/fill data, only the point data
                        // we do this by choice because of how the solar farms overlap, other features could do it differently
                        const area = d.properties.area || 0;
                        solarFarmDetails.data.value += area;
                    }
                });
                const onshoreWindTurbineDetails: LabeledDictionary<number> = {
                    label: RenewableEnergyLabels.OnshoreWindTurbines,
                    unit: getRenewableEnergyUnit(RenewableEnergyLabels.OnshoreWindTurbines),
                    data: { value: 0 },
                };
                dataOnshoreWindTurbine.forEach((d) => {
                    if (d && d.properties) {
                        const count = d.properties.point_count || 1;
                        onshoreWindTurbineDetails.data.value += count;
                    }
                });
                const detailsArr = [];
                solarFarmsOn && detailsArr.push(solarFarmDetails);
                onshoreWindTurbinesOn && detailsArr.push(onshoreWindTurbineDetails);
                return { details: detailsArr };
            }
            return {};
        },
        changeOverTimeUnitFunc: getRenewableEnergyUnit,
        changeOverTimeValueFunc: (data: Dictionary<any>) => {
            if (data && data.properties) {
                return data.properties.point_count || 1;
            } else {
                return 0;
            }
        },
    },

    [ApplicationOption.TreeCover]: {
        Options: () => {
            const { selectedLayout } = useContext(MapOptionsContext);
            return (
                <>
                    <SatelliteSelector />
                    <AnnotationSelector />
                    {selectedLayout !== LayoutOption.ChangeOverTime ? (
                        <TreeCoverFillSelector />
                    ) : null}
                </>
            );
        },
        Details: () => {
            const {
                featureDetails,
                featureDetailsSecondary,
                selectedDateOption,
                selectedDateOptionSecondary,
                selectedLayout,
            } = useContext(MapOptionsContext);
            const data = featureDetails.details?.map((v, i) => {
                return {
                    ...v,
                    value: v.data.value,
                    valueSecondary: featureDetailsSecondary.details
                        ? featureDetailsSecondary.details[i]?.data?.value
                        : undefined,
                };
            });
            return (
                <DetailsTable
                    date={selectedDateOption}
                    dateSecondary={selectedDateOptionSecondary}
                    data={data}
                    isSingle={selectedLayout === LayoutOption.Single}
                    title="Tree Cover"
                    sortFn={(a, b) => getTreeCoverOrder(a.label) - getTreeCoverOrder(b.label)}
                    getChip={(label: string) => (
                        <FeatureChip
                            bgcolor={getTreeCoverColors(label)}
                            textColor={getTreeCoverLabelColor(label)}
                            width="72px"
                            label={label}
                        />
                    )}
                />
            );
        },
        getFeatureData: (
            map: Map,
            activeFeatures?: Array<MarineInfraLabels | RenewableEnergyLabels | TreeCoverFillLabels>
        ): FeatureData => {
            if (
                map &&
                map.getSource(MapSources.HistoryTreeCover) &&
                map.isSourceLoaded(MapSources.HistoryTreeCover)
            ) {
                const treeCoverDetails: LabeledDictionary<number> = {
                    label: 'Tree Cover',
                    unit: getTreeCoverUnit(),
                    data: {},
                };
                if (activeFeatures) {
                    activeFeatures.forEach((feature) => {
                        if (feature === TreeCoverFillLabels.None) {
                            treeCoverDetails.data[NoneKey] = 0;
                        }
                        if (feature === TreeCoverFillLabels.Low) {
                            treeCoverDetails.data[LowKey] = 0;
                        }
                        if (feature === TreeCoverFillLabels.Medium) {
                            treeCoverDetails.data[MediumKey] = 0;
                        }
                        if (feature === TreeCoverFillLabels.High) {
                            treeCoverDetails.data[HighKey] = 0;
                        }
                        if (feature === TreeCoverFillLabels.Full) {
                            treeCoverDetails.data[FullKey] = 0;
                        }
                    });
                }

                const data = map.queryRenderedFeatures();
                data.forEach((d) => {
                    if (d && d.properties) {
                        const area = d.properties.area || 0;
                        const level = d.properties.level;
                        if (treeCoverDetails.data[level] !== undefined) {
                            treeCoverDetails.data[level] += area;
                        }
                    }
                });
                return {
                    details: Object.entries(treeCoverDetails.data).map(([k, v]) => {
                        return {
                            label: k,
                            unit: getTreeCoverUnit(k),
                            data: { value: v },
                        };
                    }),
                };
            }
            return {};
        },
        changeOverTimeUnitFunc: getTreeCoverUnit,
        changeOverTimeValueFunc: (data: Dictionary<any>) => {
            if (data && data.properties) {
                return data.properties.area || 0;
            } else {
                return 0;
            }
        },
    },
};
