import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import Alert from '@mui/material/Alert';
import { Content } from '@allenai/varnish2/components';
import {
    useMediaQuery,
    useTheme,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    Toolbar,
} from '@mui/material';
import { useFetch } from 'use-http';

import { Home } from './pages/Home';
import { Map } from './pages/Map';
import { FAQ } from './pages/FAQ';
import { Ai } from './pages/Ai';
import { Data } from './pages/Data';
import { SuperResGallery } from './pages/SuperResGallery';
import { AppRoute } from './AppRoute';
import { MapOptionsContext } from './context/MapOptionsContext';
import { OptionsAsMenu } from './config/uiOptionsConfig';
import { SatlasHeader } from './components/header/SatlasHeader';

const ROUTES: AppRoute[] = [
    {
        path: '/',
        label: 'Home',
        Component: Home,
    },
    {
        path: '/map',
        label: 'Map',
        Component: Map,
        MenuAdditions: OptionsAsMenu,
    },
    {
        path: '/superres',
        label: 'Super-Resolution',
        Component: SuperResGallery,
    },
    {
        path: '/ai',
        label: 'AI',
        Component: Ai,
    },
    {
        path: '/data',
        label: 'Data',
        Component: Data,
    },
    {
        path: '/faq',
        label: 'FAQ',
        Component: FAQ,
    },
];

interface MobileMenuProps {
    handleMenuToggle: () => void;
    MenuAdditions?: () => JSX.Element | null;
}

const MobileMenu = ({ handleMenuToggle, MenuAdditions }: MobileMenuProps) => {
    return (
        <Menu>
            {ROUTES.filter((r) => r.label).map(({ path, label }) => (
                <ListItem key={path} disablePadding>
                    <FullWidthLink to={path} onClick={handleMenuToggle}>
                        <DrawerMenuButton selected={location.pathname === path}>
                            {label}
                        </DrawerMenuButton>
                    </FullWidthLink>
                </ListItem>
            ))}
            {MenuAdditions ? <MenuAdditions /> : null}
        </Menu>
    );
};

export const App = () => {
    const theme = useTheme();
    const greaterThanMd = useMediaQuery(theme.breakpoints.up('md'));

    const [alertDismissed, setAlertDismissed] = useState(false);
    const showAlert =
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i);

    // Used to query the current page the user is on
    const location = useLocation();

    // Used to open and close the menu
    const [menuOpen, setMenuOpen] = useState(false);
    const handleMenuToggle = () => {
        setMenuOpen(!menuOpen);
    };

    const { setMapboxglAccess } = useContext(MapOptionsContext);

    const { get: getMapboxToken, response, error } = useFetch<string>('/api/mbglt');

    useEffect(() => {
        loadMapboxAccess();
    }, []);

    async function loadMapboxAccess() {
        const token = await getMapboxToken();
        if (response.ok) {
            setMapboxglAccess({ token });
        } else {
            setMapboxglAccess({ error });
        }
    }

    return (
        <>
            <Routes>
                {ROUTES.map(({ path, label, MenuAdditions }) => (
                    <Route
                        key={path}
                        path={path}
                        element={
                            <SatlasHeader
                                subPageHeader={label !== 'Home'}
                                endSlot={
                                    <>
                                        {greaterThanMd ? (
                                            <GrayscaleToolbar variant="dense">
                                                {ROUTES.filter((r) => r.label).map(
                                                    ({ path, label }) => (
                                                        <Link key={path} to={path}>
                                                            <ListItemButton
                                                                selected={
                                                                    location.pathname === path
                                                                }>
                                                                {label}
                                                            </ListItemButton>
                                                        </Link>
                                                    )
                                                )}
                                            </GrayscaleToolbar>
                                        ) : (
                                            <GrayscaleToolbar variant="dense">
                                                {ROUTES.length > 1 ? (
                                                    <>
                                                        <IconButton
                                                            edge="end"
                                                            onClick={handleMenuToggle}>
                                                            <GrayscaleMenuIcon />
                                                        </IconButton>
                                                        <Drawer
                                                            variant="temporary"
                                                            anchor="right"
                                                            open={menuOpen}
                                                            onClose={handleMenuToggle}
                                                            ModalProps={{
                                                                keepMounted: true, // Better open performance on mobile
                                                            }}>
                                                            <MobileMenu
                                                                handleMenuToggle={handleMenuToggle}
                                                                MenuAdditions={MenuAdditions}
                                                            />
                                                        </Drawer>
                                                    </>
                                                ) : null}
                                            </GrayscaleToolbar>
                                        )}
                                    </>
                                }
                            />
                        }
                    />
                ))}
            </Routes>
            <StyledContent main>
                {showAlert && !alertDismissed && (
                    <Alert
                        onClose={() => {
                            setAlertDismissed(true);
                        }}
                        severity="warning">
                        Satlas is currently an early prototype and was developed for the Chrome
                        browser on desktop or laptop computers. You may use this application on
                        other devices and browsers, but it has not been tested thoroughly for these
                        situations.
                    </Alert>
                )}
                <Routes>
                    {ROUTES.map(({ path, Component }) => (
                        <Route key={path} path={path} element={<Component />} />
                    ))}
                </Routes>
            </StyledContent>
        </>
    );
};

const GrayscaleToolbar = styled(Toolbar)`
    &&& a {
        color: ${({ theme }) => theme.color2.N3};
    }
`;

const GrayscaleMenuIcon = styled(MenuIcon)`
    color: ${({ theme }) => theme.color2.N3};
`;

const Menu = styled(List)`
    && {
        margin-top: ${({ theme }) => theme.spacing(3)};
    }
`;

const DrawerMenuButton = styled(ListItemButton)`
    && {
        min-width: 180px;
        max-width: 240px;
        width: 100%;
    }
`;

const FullWidthLink = styled(Link)`
    && {
        width: 100%;
    }
`;

// styling the content component so that the map spans the full page
const StyledContent = styled(Content)`
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;
