import React, { useContext } from 'react';

import { SwitchSelector } from './baseSelectors/SwitchSelector';
import { MapOptionsContext } from '../context/MapOptionsContext';

export const defaultValue = false;

interface Props {}

export const SatelliteSelector = ({ ...rest }: Props) => {
    const { showSatellite, setShowSatellite, setShowSuperResolution } =
        useContext(MapOptionsContext);

    // for Satellite and Super Resolution, we want to make sure both are not on simultaneously
    const onSelectorChange = (value: boolean): void => {
        setShowSuperResolution(false);
        setShowSatellite(value);
    };

    return (
        <SwitchSelector
            label="Satellite View"
            value={showSatellite}
            onChange={onSelectorChange}
            {...rest}
        />
    );
};
