import React from 'react';
import { MaxWidthText, Footer } from '@allenai/varnish2/components';

import { SubPageHero } from '../components/SubPageHero';
import { MainContainer } from '../components/SharedContainers';
import { CenterAlignedDiv } from '../components/utils/sharedStyles';

export const FAQ = () => {
    return (
        <MainContainer>
            <SubPageHero>
                <h1>FAQ</h1>
            </SubPageHero>
            <MaxWidthText>
                <h3>What is Satlas?</h3>
                <p>
                    Satlas is a platform for visualizing and downloading global geospatial data
                    products generated by AI using satellite images. Currently, it includes three
                    types of data: marine infrastructure (offshore wind turbines and platforms),
                    renewable energy infrastructure (onshore wind turbines and solar farms), and
                    tree cover, but we hope to include many more over time. It is our hope that this
                    data will be useful for earth and environmental scientists, as well as for
                    organizations working in a variety of geospatial domains. The marine
                    infrastructure data is already being used by Ai2’s{' '}
                    <a
                        href="https://www.skylight.global/"
                        target="_blank"
                        rel="noopener noreferrer">
                        Skylight
                    </a>{' '}
                    to detect vessels involved in illegal fishing.
                </p>
                <h3>Can I download the data?</h3>
                <p>
                    All of the data displayed in Satlas, along with training data and model weights,
                    can be{' '}
                    <a
                        href="https://github.com/allenai/satlas/blob/main/GeospatialDataProducts.md"
                        target="_blank"
                        rel="noopener noreferrer">
                        downloaded for offline analysis here
                    </a>
                    . Data is available as monthly maps starting from January 2016. Marine
                    infrastructure and renewable energy infrastructure are published as one GeoJSON
                    for each month, and tree cover is published as a set of GeoTIFFs for each month.
                </p>
                <h3>How accurate is the data?</h3>
                <p>
                    The data generated by our AI models has high accuracy. However, AI systems are
                    never perfect and several factors tend to degrade performance. An analysis of
                    the accuracy of each Satlas geospatial data product is made available in our{' '}
                    <a
                        href="https://github.com/allenai/satlas/blob/main/DataValidationReport.md"
                        target="_blank"
                        rel="noopener noreferrer">
                        Data Validation Report
                    </a>
                    .
                </p>
                <h3>Why are satellite pictures missing for my time and area?</h3>
                <p>
                    The satellite imagery in Satlas comes from the{' '}
                    <a
                        href="https://sentinel.esa.int/web/sentinel/missions/sentinel-2"
                        target="_blank"
                        rel="noopener noreferrer">
                        Sentinel-2 satellites
                    </a>
                    , which do not cover the whole planet on each pass. If satellite imagery is
                    missing for your time and location, try choosing an adjacent month to get a
                    picture from another orbit.
                </p>
                <h3>Why is it hard to annotate features in satellite imagery?</h3>
                <p>
                    The Sentinel 2 satellite imagery is fantastic in that it has frequent (weekly)
                    global coverage (although there are missing components on each pass). However,
                    compared to commercial satellite feeds it is comparatively low resolution
                    (10m/px), meaning even a large off-shore wind turbine only occupies ten or so
                    pixels in the image. In addition, changing climatic conditions through the year
                    and erratic cloud cover cause noise that the model must ignore.
                </p>
                <h3>Are there other sources of global geospatial data?</h3>
                <p>
                    Other websites include:
                    <ul>
                        <li>
                            <a
                                href="https://www.globalforestwatch.org/"
                                target="_blank"
                                rel="noopener noreferrer">
                                Global Forest Watch
                            </a>{' '}
                            collects various forest-related datasets including tree cover, tree
                            gain, and tree loss. Satlas also includes tree cover data: our hope is
                            that this data will be complementary to Global Forest Watch, with high
                            frequency (monthly) updates, especially as we work to improve the
                            quality of our models over time.
                        </li>
                        <li>
                            <a
                                href="https://www.openstreetmap.org"
                                target="_blank"
                                rel="noopener noreferrer">
                                OpenStreetMap
                            </a>{' '}
                            is an open map dataset that includes offshore infrastructure features.
                            We’ve observed many objects identified by our models that are missing
                            from OpenStreetMap, and believe that these detections may be helpful for
                            improving OpenStreetMap.
                        </li>
                        <li>
                            Earthrise Media maintains several websites highlighting data relevant to
                            environmental issues.{' '}
                            <a
                                href="https://globalplasticwatch.org/"
                                target="_blank"
                                rel="noopener noreferrer">
                                Global Plastic Watch
                            </a>{' '}
                            shows plastic pollution sites, and{' '}
                            <a
                                href="https://amazonminingwatch.org/"
                                target="_blank"
                                rel="noopener noreferrer">
                                Amazon Mining Watch
                            </a>{' '}
                            tracks mining activities in the Amazon rainforest.
                        </li>
                        <li>
                            <a
                                href="https://hub.arcgis.com/"
                                target="_blank"
                                rel="noopener noreferrer">
                                ArcGIS
                            </a>{' '}
                            and{' '}
                            <a
                                href="https://developers.google.com/earth-engine/datasets/catalog"
                                target="_blank"
                                rel="noopener noreferrer">
                                Google Earth Engine
                            </a>{' '}
                            data catalogs publish numerous datasets developed by various
                            organizations, including{' '}
                            <a
                                href="https://developers.google.com/earth-engine/datasets/catalog/WRI_GPPD_power_plants"
                                target="_blank"
                                rel="noopener noreferrer">
                                Global Power Plant Database
                            </a>{' '}
                            and{' '}
                            <a
                                href="https://hub.arcgis.com/datasets/1e7dfd86350e4e00b722b943a02cb14c"
                                target="_blank"
                                rel="noopener noreferrer">
                                World Port Index
                            </a>
                            . Unfortunately many of these datasets are regional or no longer
                            maintained. We anticipate Satlas Explorer being a more unified hub for
                            global, frequently updated geospatial data.
                        </li>
                    </ul>
                </p>
                <h3>Is there code I can download?</h3>
                <p>
                    Yes! Model parameters and code are{' '}
                    <a
                        href="https://github.com/allenai/satlas"
                        target="_blank"
                        rel="noopener noreferrer">
                        available on Github
                    </a>
                    .
                </p>
            </MaxWidthText>
            <CenterAlignedDiv>
                <Footer />
            </CenterAlignedDiv>
        </MainContainer>
    );
};
