import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, SliderThumb } from '@mui/material';
import { PlayArrow, Stop, ArrowRightAlt } from '@mui/icons-material';
import { color2 } from '@allenai/varnish2/theme';

import { DateOption } from './YearSelector';
import { BaseSlider, sliderHeightScalingValue } from './baseSelectors/BaseSlider';

interface Props {
    options: DateOption[];
    optionsToLabel: DateOption[];
    curValue: DateOption;
    startValue: DateOption;
    endValue: DateOption;
    onCurValueChange: (value: DateOption) => void;
    onStartValueChange: (value: DateOption) => void;
    onEndValueChange: (value: DateOption) => void;
    className?: string;
}

export const AnimationStartStopSlider = ({
    options,
    optionsToLabel,
    curValue,
    startValue,
    endValue,
    onCurValueChange,
    onStartValueChange,
    onEndValueChange,

    className,
}: Props) => {
    const convertNumberToDateOption = (value: number) => {
        return options[value];
    };
    const convertDateOptionToNumber = (value: DateOption) => {
        return options.indexOf(value);
    };

    // local number value array to be used as the slider range values
    const [sliderRangeValues, setSliderRangeValues] = useState<number[]>([
        convertDateOptionToNumber(startValue) || 0,
        convertDateOptionToNumber(curValue) || options.length / 2,
        convertDateOptionToNumber(endValue) || options.length - 1,
    ]);

    const handleChange = (_event: Event, newValue: number | number[]) => {
        setSliderRangeValues(newValue as number[]);
        onStartValueChange(options[sliderRangeValues[0]]);
        onCurValueChange(options[sliderRangeValues[1]]);
        onEndValueChange(options[sliderRangeValues[2]]);
    };

    useEffect(() => {
        // update local number value when option value changes
        setSliderRangeValues([
            sliderRangeValues[0],
            convertDateOptionToNumber(curValue),
            sliderRangeValues[2],
        ]);
    }, [curValue]);

    return (
        <Container sx={{ height: `${optionsToLabel.length * sliderHeightScalingValue}px` }}>
            <BaseSlider
                className={className}
                track={false}
                value={sliderRangeValues}
                orientation="vertical"
                onChange={handleChange}
                getAriaValueText={convertNumberToDateOption}
                valueLabelFormat={convertNumberToDateOption}
                min={0}
                max={options.length - 1 || 1}
                step={1}
                valueLabelDisplay="off"
                slots={{
                    thumb: (v) => {
                        const { children, ...rest } = v;
                        if (v['data-index'] === 0) {
                            return (
                                <IconThumbComponent {...rest}>
                                    <PlayArrow fontSize="small" sx={{ color: color2.G5.hex }} />
                                    {children}
                                </IconThumbComponent>
                            );
                        }
                        if (v['data-index'] === 2) {
                            return (
                                <IconThumbComponent {...rest}>
                                    <Stop fontSize="small" sx={{ color: color2.R4.hex }} />
                                    {children}
                                </IconThumbComponent>
                            );
                        }
                        return (
                            <ArrowComponent {...rest}>
                                <ArrowRightAlt />
                                {children}
                            </ArrowComponent>
                        );
                    },
                }}
                marks={optionsToLabel.map((v) => {
                    return { value: options.indexOf(v), label: v };
                })}
            />
        </Container>
    );
};

const Container = styled(Box)`
    margin: ${({ theme }) => `${theme.spacing(2)} 0 ${theme.spacing(2)} ${theme.spacing(3)}`};
`;

const IconThumbComponent = ({ children, ...other }: any) => {
    return (
        <SliderThumb
            sx={{
                zIndex: 99,
                left: '3px',
                background: 'white',
                border: '1px ridge currentColor',
                height: 18,
                width: 18,
                borderRadius: 0,
            }}
            {...other}>
            {children}
        </SliderThumb>
    );
};

const ArrowComponent = ({ children, ...other }: any) => {
    return (
        <SliderThumb
            sx={{
                borderRadius: 0,
                background: 'white',
                border: '1px ridge currentColor',
                height: 12,
                width: 24,
                left: 17,
            }}
            {...other}>
            {children}
        </SliderThumb>
    );
};
