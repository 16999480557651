import * as React from 'react';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import { Content } from '@allenai/varnish2/components';

import { SatlasLogo } from '../../images/SatlasLogo';
import ai2logosrc from '../../images/Ai2_logo_white_RGB.svg';

export interface Props {
    subPageHeader?: boolean; // for Satlas headers, we need a different treatment for home page vs. others
    endSlot?: React.ReactNode; // a space on the right side of the banner for additional content
}

export const SatlasHeader = ({ subPageHeader, endSlot }: Props) => {
    return (
        <BannerContainer>
            <BannerContent>
                <Grid container justifyContent="space-between" spacing={2}>
                    <Grid item>
                        <a href="https://allenai.org">
                            {subPageHeader ? (
                                <img src={ai2logosrc} alt="Ai2 Logo" />
                            ) : (
                                <PaddedImage src={ai2logosrc} alt="Ai2 Logo" />
                            )}
                        </a>
                        {subPageHeader && (
                            <SatlasLogoSpan>
                                <a href="/">
                                    <SatlasLogo width={108} height={32} />
                                </a>
                            </SatlasLogoSpan>
                        )}
                    </Grid>
                    {endSlot && <Grid item>{endSlot}</Grid>}
                </Grid>
            </BannerContent>
        </BannerContainer>
    );
};

export const BannerLink = styled.a`
    display: inline-block;
    padding: 5px 0 2px 0;
`;

const PaddedImage = styled.img`
    padding-top: ${({ theme }) => theme.spacing(1)};
`;

const BannerContent = styled(Content)`
    padding-top: ${({ theme }) => theme.spacing(1)};
    padding-bottom: ${({ theme }) => theme.spacing(1)};
`;

const BannerContainer = styled.div`
    background: black;
    padding: ${({ theme }) => theme.spacing(0.5)};
    position: sticky;
    top: 0;
    z-index: 999;
`;

// for the header that shows the SatlasLogo, we need different
// svg styling and dimensions
const SatlasLogoSpan = styled.span`
    border-left: solid rgba(255, 255, 255, 0.33);
    border-left-width: 1px;
    display: inline-block;
    height: 32px;
    margin-top: ${({ theme }) => theme.spacing(1)};
    padding-left: ${({ theme }) => theme.spacing(3)};
    margin-left: ${({ theme }) => theme.spacing(2)};

    &&&&& svg {
        fill: #fff;
        stroke: #fff;
        stroke-width: 2;
    }
`;
