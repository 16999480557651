import React, { useContext } from 'react';

import { RadioSelector } from './baseSelectors/RadioSelector';
import { MapOptionsContext } from '../context/MapOptionsContext';

export enum ApplicationOption {
    MarineInfra = 'Marine Infrastructure',
    RenewableEnergy = 'Renewable Energy',
    TreeCover = 'Tree Cover',
    SuperResolution = 'Super Resolution',
}

export const options = Object.values(ApplicationOption);

export type OptionType = (typeof options)[number];
export const defaultValue = options[0];

export const getApplicationTooltip = (application: OptionType) => {
    switch (application) {
        case ApplicationOption.MarineInfra:
            return 'Marine Infrastructure consists of offshore wind turbines and platforms. Offshore platforms include storage, oil, gas, and mooring platforms, and barges.';
        case ApplicationOption.RenewableEnergy:
            return 'Renewable Energy consists of wind turbines and solar panels. Turbines are represented individually as points, and solar panels are highlighted with a polygon around the solar farm.';
        case ApplicationOption.TreeCover:
            return 'Tree Cover highlights how forest density varies across the world by coloring the map. For example, "High" tree cover corresponds with dense forests, and "Low" tree cover corresponds with environments with less vegetation.';
        case ApplicationOption.SuperResolution:
            return 'Super Resolution shows satellite imagery that has been increased by 4x in resolution using an AI model, from 10 m/pixel to 2.5 m/pixel. The generated imagery may erroneously contain mislabeled artifacts.';
        default:
            return null;
    }
};

interface Props {}

export const ApplicationSelector = ({ ...rest }: Props) => {
    const { selectedApplication, setSelectedApplication } = useContext(MapOptionsContext);
    return (
        <RadioSelector<OptionType>
            options={options}
            value={selectedApplication}
            onChange={setSelectedApplication}
            getTooltip={getApplicationTooltip}
            {...rest}
        />
    );
};
