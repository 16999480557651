import React, { useContext } from 'react';
import styled from 'styled-components';
import { Stack, TableCell } from '@mui/material';
import { East, NorthEast, SouthEast } from '@mui/icons-material';
import { color2 } from '@allenai/varnish2/theme';

import { MapOptionsContext } from '../../context/MapOptionsContext';
import { LayoutOption } from '../LayoutSelector';
import { ApplicationConfig } from '../../config/applicationConfig';
import { ChangeOverTimeDetails } from './ChangeOverTimeDetails';
import { LabeledDictionary } from '../utils/base';
import { ApplicationOption } from '../ApplicationSelector';

export interface DetailsData {
    label: string;
    unit: string;
    value: number;
    valueSecondary?: number;
}

export interface FeatureData {
    layout?: LayoutOption;
    application?: ApplicationOption;
    details?: LabeledDictionary<number>[];
}

export interface Props {
    className?: string;
}

export const FeatureDetails = ({ className }: Props) => {
    const { featureDetails, featureDetailsSecondary, selectedLayout, selectedApplication } =
        useContext(MapOptionsContext);
    const Details = ApplicationConfig[selectedApplication].Details;

    if (
        featureDetails.application !== selectedApplication ||
        featureDetails.layout !== selectedLayout
    ) {
        // there has been a change to the data and we should not render until we get updated data
        return null;
    }

    if (
        (featureDetails.layout === LayoutOption.Split ||
            featureDetails.layout === LayoutOption.SideBySide) &&
        (featureDetails.application !== featureDetailsSecondary.application ||
            featureDetails.layout !== featureDetailsSecondary.layout)
    ) {
        // secondary needs to be updated
        return null;
    }

    return (
        <Container spacing={1} className={className}>
            {selectedLayout !== LayoutOption.ChangeOverTime ? (
                <Details />
            ) : (
                <ChangeOverTimeDetails details={featureDetails.details} />
            )}
        </Container>
    );
};

const Container = styled(Stack)`
    max-height: 500px;
    overflow-y: auto;
`;

export const ChangeArrow = ({ a, b }: { a: number; b: number }) => {
    const dif = a - b;
    const changeP = dif / (a + b + 1);
    // call fairly flat 0
    if (Math.abs(changeP) < 0.002) {
        return <East sx={{ color: color2.N3.hex, height: 18 }} />;
    }
    if (dif > 0) {
        return <NorthEast sx={{ color: color2.G3.hex, height: 18 }} />;
    } else {
        return <SouthEast sx={{ color: color2.R3.hex, height: 18 }} />;
    }
};

export const NarrowTableCell = styled(TableCell)`
    &&& {
        padding: ${({ theme }) => `${theme.spacing(0.25)} ${theme.spacing(0.5)}`};
        font-weight: normal;
    }
`;

export const getFormattedValue = (value: number = 0, unit: string = '') => {
    return `${value.toLocaleString('en-US', {
        notation: 'compact',
        compactDisplay: 'short',
    })} ${unit}`;
};
