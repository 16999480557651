import React, { useContext } from 'react';

import { SwitchSelector } from './baseSelectors/SwitchSelector';
import { MapOptionsContext } from '../context/MapOptionsContext';

export const defaultValue = false;

interface Props {}

export const AnnotationSelector = ({ ...rest }: Props) => {
    const { hideAnnotation, setHideAnnotation } = useContext(MapOptionsContext);
    return (
        <SwitchSelector
            label="Hide Annotations"
            value={hideAnnotation}
            onChange={setHideAnnotation}
            {...rest}
        />
    );
};
