import React from 'react';
import styled from 'styled-components';

import favyenSrc from '../images/credits/favyen.jpg';
import piperSrc from '../images/credits/piper.jpg';
import aniSrc from '../images/credits/ani.jpg';
import jonSrc from '../images/credits/jon.jpg';
import arnaviSrc from '../images/credits/arnavi.jpg';
import aaronSrc from '../images/credits/aaron.jpg';
import michaelSrc from '../images/credits/michael.jpg';

interface Person {
    imgSrc: string;
    name: string;
}

interface Props {
    people: Person[];
}

const mlTeam: Person[] = [
    {
        name: 'Favyen Bastani',
        imgSrc: favyenSrc,
    },
    {
        name: 'Piper Wolters',
        imgSrc: piperSrc,
    },
    {
        name: 'Ani Kembhavi',
        imgSrc: aniSrc,
    },
];

const webTeam: Person[] = [
    {
        name: 'Jon Borchardt',
        imgSrc: jonSrc,
    },
    {
        name: 'Arnavi Chheda',
        imgSrc: arnaviSrc,
    },
    {
        name: 'Aaron Sarnat',
        imgSrc: aaronSrc,
    },
    {
        name: 'Michael Schmitz',
        imgSrc: michaelSrc,
    },
];

export const Credits = () => (
    <CreditsContent>
        <h4>Computer Vision Team</h4>
        <TeamList people={mlTeam} />

        <h4>Research Visualization Team</h4>
        <TeamList people={webTeam} />
    </CreditsContent>
);

const CreditsContent = styled.div``;

const TeamList = ({ people }: Props) => (
    <List>
        {people.map((person, i) => {
            return (
                <div key={`${person.name}-${i}`}>
                    <img width="160" height="160" src={person.imgSrc} alt={person.name}></img>
                    <PersonInfo>
                        <strong>{person.name}</strong>
                    </PersonInfo>
                </div>
            );
        })}
    </List>
);

const PersonInfo = styled.div`
    margin: 0 auto;
    text-align: center;
`;

const List = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing(2)};
`;

export default Credits;
