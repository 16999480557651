import React, { useState, useEffect } from 'react';
import { ViewState } from 'react-map-gl';

import debounce from '@mui/utils/debounce';

import { DateOption, DataTimeRange } from '../components/YearSelector';
import {
    TreeCoverFillOption,
    defaultValue as fillDefaultValue,
} from '../components/TreeCoverFillSelector';
import { LayoutOption, defaultValue as layoutDefaultValue } from '../components/LayoutSelector';
import {
    ApplicationOption,
    defaultValue as applicationDefaultValue,
} from '../components/ApplicationSelector';
import { defaultValue as animationDefaultValue } from '../components/AnimationSelector';
import { defaultValue as heatDefaultValue } from '../components/HeatSelector';
import { defaultValue as satelliteDefaultValue } from '../components/SatelliteSelector';
import { defaultValue as superResolutionDefaultValue } from '../components/SuperResolutionSelector';
import { defaultValue as annotationDefaultValue } from '../components/AnnotationSelector';
import { FeatureData } from '../components/details/FeatureDetails';
import {
    MarineInfraOption,
    defaultValue as marineInfraDefaultValue,
} from '../components/MarineInfraSelector';
import {
    RenewableEnergyOption,
    defaultValue as renewableEnergyDefaultValue,
} from '../components/RenewableEnergySelector';

// values we will store in the url
export interface StateMapOptions {
    view: ViewState;
    showAnimation: boolean;
    showHeatmap: boolean;
    showSatellite: boolean;
    showSuperResolution: boolean;
    hideAnnotation: boolean;
    selectedDateOption: DateOption; // year options for side-by-side views
    selectedDateOptionSecondary: DateOption; // year options for side-by-side views
    selectedStartDateOption: DateOption; // year options for time range views
    selectedEndDateOption: DateOption; // year options for time range views
    selectedTreeCoverFill: TreeCoverFillOption;
    selectedMarineInfra: MarineInfraOption;
    selectedRenewableEnergy: RenewableEnergyOption;
    selectedApplication: ApplicationOption;
    selectedLayout: LayoutOption;
}

export interface MapboxglAccess {
    token?: string;
    error?: Error;
}

export interface Bounds {
    location: [[number, number], [number, number]];
    padding?: number;
}

// all map option context values
export interface MapOptions extends StateMapOptions {
    resetMap: (value: StateMapOptions) => void;
    setView: (value: ViewState) => void;
    setShowAnimation: (value: boolean) => void;
    setShowHeatmap: (value: boolean) => void;
    setShowSatellite: (value: boolean) => void;
    setShowSuperResolution: (value: boolean) => void;
    setHideAnnotation: (value: boolean) => void;
    setSelectedDateOption: (value: DateOption) => void;
    setSelectedDateOptionSecondary: (value: DateOption) => void;
    setSelectedStartDateOption: (value: DateOption) => void;
    setSelectedEndDateOption: (value: DateOption) => void;
    setSelectedTreeCoverFill: (value: TreeCoverFillOption) => void;
    setSelectedMarineInfra: (value: MarineInfraOption) => void;
    setSelectedRenewableEnergy: (value: MarineInfraOption) => void;
    setSelectedApplication: (value: ApplicationOption) => void;
    setSelectedLayout: (value: LayoutOption) => void;
    setFeatureDetails: (value: FeatureData) => void;
    setFeatureDetailsSecondary: (value: FeatureData) => void;
    setMapboxglAccess: (value: MapboxglAccess) => void;
    setFitToBounds: (value?: Bounds) => void;
    setMouseLatLon: (value?: [number, number]) => void;

    // these are not saved in url state
    featureDetails: FeatureData;
    featureDetailsSecondary: FeatureData;
    mapboxglAccess: MapboxglAccess;
    fitToBounds?: Bounds;
    mouseLatLon?: [number, number];
}

const defaultMapOptions: MapOptions = {
    mapboxglAccess: {},
    setFitToBounds() {},
    setMapboxglAccess() {},
    setMouseLatLon() {},

    resetMap() {},

    view: {
        latitude: 55.96,
        longitude: 18.47,
        zoom: 3.55,
        bearing: 0,
        pitch: 0,
        padding: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
        },
    },
    setView() {},

    showAnimation: animationDefaultValue,
    setShowAnimation() {},

    showHeatmap: heatDefaultValue,
    setShowHeatmap() {},

    showSatellite: satelliteDefaultValue,
    setShowSatellite() {},

    showSuperResolution: superResolutionDefaultValue,
    setShowSuperResolution() {},

    hideAnnotation: annotationDefaultValue,
    setHideAnnotation() {},

    selectedDateOption: DataTimeRange.end.year + '-' + DataTimeRange.end.month,
    setSelectedDateOption() {},

    selectedDateOptionSecondary: DataTimeRange.start.year + '-' + DataTimeRange.start.month,
    setSelectedDateOptionSecondary() {},

    selectedStartDateOption: DataTimeRange.start.year + '-' + DataTimeRange.start.month,
    setSelectedStartDateOption() {},

    selectedEndDateOption: DataTimeRange.end.year + '-' + DataTimeRange.end.month,
    setSelectedEndDateOption() {},

    selectedTreeCoverFill: fillDefaultValue,
    setSelectedTreeCoverFill() {},

    selectedMarineInfra: marineInfraDefaultValue,
    setSelectedMarineInfra() {},

    selectedRenewableEnergy: renewableEnergyDefaultValue,
    setSelectedRenewableEnergy() {},

    selectedApplication: applicationDefaultValue,
    setSelectedApplication() {},

    selectedLayout: layoutDefaultValue,
    setSelectedLayout() {},

    featureDetails: { details: undefined, application: undefined, layout: undefined },
    setFeatureDetails() {},

    featureDetailsSecondary: { details: undefined, application: undefined, layout: undefined },
    setFeatureDetailsSecondary() {},
};

export const MapOptionsContext = React.createContext<MapOptions>(defaultMapOptions);

interface MapOptionsContextProviderProps {
    children: React.ReactNode | React.ReactNode[];
}

export const MapOptionsContextProvider = ({ children }: MapOptionsContextProviderProps) => {
    const [mapboxglAccess, setMapboxglAccess] = useState<MapboxglAccess>(
        defaultMapOptions.mapboxglAccess
    );
    const [mouseLatLon, setMouseLatLon] = useState<[number, number]>();
    const [fitToBounds, setFitToBounds] = useState<Bounds>();
    const [view, setView] = useState<ViewState>({
        ...defaultMapOptions.view,
    });
    const [showHeatmap, setShowHeatmap] = useState<boolean>(defaultMapOptions.showHeatmap);
    const [showAnimation, setShowAnimation] = useState<boolean>(defaultMapOptions.showAnimation);
    const [showSatellite, setShowSatellite] = useState<boolean>(defaultMapOptions.showSatellite);
    const [showSuperResolution, setShowSuperResolution] = useState<boolean>(
        defaultMapOptions.showSuperResolution
    );
    const [hideAnnotation, setHideAnnotation] = useState<boolean>(defaultMapOptions.hideAnnotation);
    const [selectedDateOption, setSelectedDateOption] = useState<DateOption>(
        defaultMapOptions.selectedDateOption
    );
    const [selectedDateOptionSecondary, setSelectedDateOptionSecondary] = useState<DateOption>(
        defaultMapOptions.selectedDateOptionSecondary
    );
    const [selectedStartDateOption, setSelectedStartDateOption] = useState<DateOption>(
        defaultMapOptions.selectedStartDateOption
    );
    const [selectedEndDateOption, setSelectedEndDateOption] = useState<DateOption>(
        defaultMapOptions.selectedEndDateOption
    );
    const [selectedTreeCoverFill, setSelectedTreeCoverFill] = useState<TreeCoverFillOption>(
        defaultMapOptions.selectedTreeCoverFill
    );

    const [selectedMarineInfra, setSelectedMarineInfra] = useState<MarineInfraOption>(
        defaultMapOptions.selectedMarineInfra
    );

    const [selectedRenewableEnergy, setSelectedRenewableEnergy] = useState<RenewableEnergyOption>(
        defaultMapOptions.selectedRenewableEnergy
    );

    const [selectedApplication, setSelectedApplication] = useState<ApplicationOption>(
        defaultMapOptions.selectedApplication
    );
    const [selectedLayout, setSelectedLayout] = useState<LayoutOption>(
        defaultMapOptions.selectedLayout
    );
    const [featureDetails, setFeatureDetails] = useState<FeatureData>(
        defaultMapOptions.featureDetails
    );
    const [featureDetailsSecondary, setFeatureDetailsSecondary] = useState<FeatureData>(
        defaultMapOptions.featureDetailsSecondary
    );

    // set the state based on whats passed in
    const resetMap = (value: Partial<StateMapOptions>) => {
        setView(value.view || defaultMapOptions.view);
        setShowHeatmap(
            value.showHeatmap !== undefined ? value.showHeatmap : defaultMapOptions.showHeatmap
        );
        setShowAnimation(
            value.showAnimation !== undefined
                ? value.showAnimation
                : defaultMapOptions.showAnimation
        );
        setShowSatellite(
            value.showSatellite !== undefined
                ? value.showSatellite
                : defaultMapOptions.showSatellite
        );
        setShowSuperResolution(
            value.showSuperResolution !== undefined
                ? value.showSuperResolution
                : defaultMapOptions.showSuperResolution
        );
        setHideAnnotation(
            value.hideAnnotation !== undefined
                ? value.hideAnnotation
                : defaultMapOptions.hideAnnotation
        );
        setSelectedDateOption(value.selectedDateOption || defaultMapOptions.selectedDateOption);
        setSelectedDateOptionSecondary(
            value.selectedDateOptionSecondary || defaultMapOptions.selectedDateOptionSecondary
        );
        setSelectedStartDateOption(
            value.selectedStartDateOption || defaultMapOptions.selectedStartDateOption
        );
        setSelectedEndDateOption(
            value.selectedEndDateOption || defaultMapOptions.selectedEndDateOption
        );
        setSelectedTreeCoverFill(
            value.selectedTreeCoverFill || defaultMapOptions.selectedTreeCoverFill
        );
        setSelectedMarineInfra(value.selectedMarineInfra || defaultMapOptions.selectedMarineInfra);
        setSelectedRenewableEnergy(
            value.selectedRenewableEnergy || defaultMapOptions.selectedRenewableEnergy
        );
        setSelectedApplication(value.selectedApplication || defaultMapOptions.selectedApplication);
        setSelectedLayout(value.selectedLayout || defaultMapOptions.selectedLayout);
        setFeatureDetails({ details: undefined, application: undefined, layout: undefined });
        setFeatureDetailsSecondary({
            details: undefined,
            application: undefined,
            layout: undefined,
        });
    };

    // whenever a state variable changes, we update the url without navigating
    useEffect(
        debounce(() => {
            // we only want to alter state if we are on map.
            // the more correct fix would  be to put state on to the map page.
            // but that takes soem doing to give access to context to all things that map uses.
            // this is fine for now.
            const page = new URL(window.location.href).pathname.split('/')[1];
            if (page === 'map') {
                const curState = {
                    view: { ...view, padding: undefined },
                    showHeatmap,
                    showAnimation,
                    showSatellite,
                    selectedDateOption,
                    selectedDateOptionSecondary,
                    selectedStartDateOption,
                    selectedEndDateOption,
                    selectedTreeCoverFill,
                    selectedMarineInfra,
                    selectedRenewableEnergy,
                    selectedApplication,
                    selectedLayout,
                };
                window.history.replaceState(null, '', `/map?state=${JSON.stringify(curState)}`);
            }
        }, 1000),
        [
            view,
            showHeatmap,
            showAnimation,
            showSatellite,
            selectedDateOption,
            selectedDateOptionSecondary,
            selectedStartDateOption,
            selectedEndDateOption,
            selectedTreeCoverFill,
            selectedMarineInfra,
            selectedRenewableEnergy,
            selectedApplication,
            selectedLayout,
        ]
    );

    return (
        <MapOptionsContext.Provider
            value={{
                fitToBounds,
                setFitToBounds,
                mouseLatLon,
                setMouseLatLon,
                mapboxglAccess,
                setMapboxglAccess,
                resetMap,
                view,
                setView,
                showAnimation: showAnimation,
                setShowAnimation: setShowAnimation,
                showHeatmap,
                setShowHeatmap,
                showSatellite,
                setShowSatellite,
                showSuperResolution,
                setShowSuperResolution,
                hideAnnotation,
                setHideAnnotation,
                selectedDateOption,
                setSelectedDateOption,
                selectedDateOptionSecondary,
                setSelectedDateOptionSecondary,
                selectedStartDateOption,
                setSelectedStartDateOption,
                selectedEndDateOption,
                setSelectedEndDateOption,
                selectedTreeCoverFill,
                setSelectedTreeCoverFill,
                selectedMarineInfra,
                setSelectedMarineInfra,
                selectedRenewableEnergy,
                setSelectedRenewableEnergy,
                selectedApplication,
                setSelectedApplication,
                selectedLayout,
                setSelectedLayout,
                featureDetails,
                setFeatureDetails,
                featureDetailsSecondary,
                setFeatureDetailsSecondary,
            }}>
            {children}
        </MapOptionsContext.Provider>
    );
};
