import React from 'react';
import styled from 'styled-components';
import { Slider, SliderProps } from '@mui/material';

export const sliderHeightScalingValue = 33;

export const BaseSlider = ({ className, slots, ...rest }: SliderProps) => {
    return (
        <Slider
            className={className}
            slots={{
                markLabel: Mark,
                ...slots,
            }}
            {...rest}
        />
    );
};

const Mark = styled.span`
    color: ${({ theme }) => theme.color2.N4};
    position: absolute;
    white-space: nowrap;
    left: ${({ theme }) => theme.spacing(4)};
    transform: translateY(50%);
`;
