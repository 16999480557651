import React, { useContext } from 'react';

import { Typography } from '@mui/material';

import { MapOptionsContext } from '../context/MapOptionsContext';
import { CheckboxSelector, CheckboxType } from './baseSelectors/CheckboxSelector';

export enum TreeCoverFillLabels {
    None = 'None',
    Low = 'Low',
    Medium = 'Medium',
    High = 'High',
    Full = 'Full',
}

const options: CheckboxType[] = [
    { label: TreeCoverFillLabels.None, isChecked: false },
    { label: TreeCoverFillLabels.Low, isChecked: false },
    { label: TreeCoverFillLabels.Medium, isChecked: true },
    { label: TreeCoverFillLabels.High, isChecked: true },
    { label: TreeCoverFillLabels.Full, isChecked: true },
];

export type TreeCoverFillOption = typeof options;
export const defaultValue = options;

interface Props {}

export const TreeCoverFillSelector = ({ ...rest }: Props) => {
    const { selectedTreeCoverFill, setSelectedTreeCoverFill } = useContext(MapOptionsContext);

    return (
        <>
            <Typography>Tree Cover Level:</Typography>
            <CheckboxSelector
                options={selectedTreeCoverFill}
                setActiveOptions={setSelectedTreeCoverFill}
                {...rest}
            />
        </>
    );
};
