import React from 'react';
import styled from 'styled-components';
import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import { Footer, MaxWidthText } from '@allenai/varnish2/components';

import {
    BigButton,
    MainContainer,
    MaxWidthContent,
    StackableGreySection,
    StackableSection,
} from '../components/SharedContainers';
import { SubPageHero } from '../components/SubPageHero';

import windfarmInstallSrc from '../images/windfarm-install.jpg';
import brazilSolarSrc from '../images/brazil-solar.jpg';
import loggingWashingtonSrc from '../images/logging-washington.jpg';
import superResMarineSrc from '../images/super-res-marine.jpg';
import { CenterAlignedDiv } from '../components/utils/sharedStyles';

const examples = [
    {
        src: windfarmInstallSrc,
        alt: 'A new windfarm near Denmark',
        label: 'Marine Infrastructure',
        url: '/map?state=%7B%22view%22:%7B%22longitude%22:7.34471592671585,%22latitude%22:54.813835109212306,%22zoom%22:7.745858277401581,%22pitch%22:0,%22bearing%22:0%7D,%22showHeatmap%22:false,%22showAnimation%22:false,%22showSatellite%22:false,%22selectedDateOption%22:%222017-01%22,%22selectedDateOptionSecondary%22:%222022-01%22,%22selectedStartDateOption%22:%222017-01%22,%22selectedEndDateOption%22:%222022-01%22,%22selectedTreeCoverFill%22:%5B%7B%22label%22:%22None%22,%22isChecked%22:false%7D,%7B%22label%22:%22Low%22,%22isChecked%22:false%7D,%7B%22label%22:%22Medium%22,%22isChecked%22:true%7D,%7B%22label%22:%22High%22,%22isChecked%22:true%7D,%7B%22label%22:%22Full%22,%22isChecked%22:true%7D%5D,%22selectedApplication%22:%22Marine%20Infrastructure%22,%22selectedLayout%22:%22Change%20Over%20Time%22%7D',
    },
    {
        src: brazilSolarSrc,
        alt: 'Solar development in Brazil',
        label: 'Renewable Energy',
        url: '/map?state={%22view%22:{%22longitude%22:-47.924356069985436,%22latitude%22:-16.609675526604832,%22zoom%22:4.416484721877065,%22pitch%22:0,%22bearing%22:0},%22showHeatmap%22:true,%22showAnimation%22:false,%22showSatellite%22:false,%22selectedDateOption%22:%222022-10%22,%22selectedDateOptionSecondary%22:%222016-08%22,%22selectedStartDateOption%22:%222016-01%22,%22selectedEndDateOption%22:%222022-12%22,%22selectedTreeCoverFill%22:[{%22label%22:%22None%22,%22isChecked%22:false},{%22label%22:%22Low%22,%22isChecked%22:false},{%22label%22:%22Medium%22,%22isChecked%22:true},{%22label%22:%22High%22,%22isChecked%22:true},{%22label%22:%22Full%22,%22isChecked%22:true}],%22selectedMarineInfra%22:[{%22label%22:%22Offshore%20Wind%20Turbines%22,%22isChecked%22:true},{%22label%22:%22Offshore%20Platforms%22,%22isChecked%22:false}],%22selectedRenewableEnergy%22:[{%22label%22:%22Solar%20Farms%22,%22isChecked%22:true},{%22label%22:%22Onshore%20Wind%20Turbines%22,%22isChecked%22:true}],%22selectedApplication%22:%22Renewable%20Energy%22,%22selectedLayout%22:%22Side%20by%20Side%22}',
    },
    {
        src: loggingWashingtonSrc,
        alt: 'Logging in Washington State',
        label: 'Tree Cover',
        url: '/map?state={%22view%22:{%22longitude%22:-124.37448812157066,%22latitude%22:48.03320862466225,%22zoom%22:10.212492495527849,%22pitch%22:0,%22bearing%22:0},%22showHeatmap%22:false,%22showAnimation%22:false,%22showSatellite%22:true,%22selectedDateOption%22:%222016-12%22,%22selectedDateOptionSecondary%22:%222021-12%22,%22selectedStartDateOption%22:%222017-01%22,%22selectedEndDateOption%22:%222022-01%22,%22selectedTreeCoverFill%22:[{%22label%22:%22None%22,%22isChecked%22:false},{%22label%22:%22Low%22,%22isChecked%22:false},{%22label%22:%22Medium%22,%22isChecked%22:true},{%22label%22:%22High%22,%22isChecked%22:true},{%22label%22:%22Full%22,%22isChecked%22:true}],%22selectedApplication%22:%22Tree%20Cover%22,%22selectedLayout%22:%22Change%20Over%20Time%22}',
    },
    {
        src: superResMarineSrc,
        alt: 'Super-resolution of a marina',
        label: 'Super-Resolution',
        url: '/map?state={%22view%22:{%22longitude%22:-122.22242118181879,%22latitude%22:47.99824713716666,%22zoom%22:15.010202332643606,%22pitch%22:0,%22bearing%22:0},%22showHeatmap%22:false,%22showAnimation%22:false,%22showSatellite%22:false,%22selectedDateOption%22:%222022-10%22,%22selectedDateOptionSecondary%22:%222016-08%22,%22selectedStartDateOption%22:%222016-07%22,%22selectedEndDateOption%22:%222022-12%22,%22selectedTreeCoverFill%22:[{%22label%22:%22None%22,%22isChecked%22:false},{%22label%22:%22Low%22,%22isChecked%22:false},{%22label%22:%22Medium%22,%22isChecked%22:true},{%22label%22:%22High%22,%22isChecked%22:true},{%22label%22:%22Full%22,%22isChecked%22:true}],%22selectedMarineInfra%22:[{%22label%22:%22Offshore%20Wind%20Turbines%22,%22isChecked%22:true},{%22label%22:%22Offshore%20Platforms%22,%22isChecked%22:false}],%22selectedRenewableEnergy%22:[{%22label%22:%22Solar%20Farms%22,%22isChecked%22:false},{%22label%22:%22Onshore%20Wind%20Turbines%22,%22isChecked%22:true}],%22selectedApplication%22:%22Super%20Resolution%22,%22selectedLayout%22:%22Single%20Map%22}',
    },
];

export const Data = () => {
    return (
        <MainContainer>
            <SubPageHero>
                <h1>AI-Generated Geospatial Data</h1>
            </SubPageHero>

            <StackableSection>
                <MaxWidthText>
                    <h3>Explore Examples</h3>
                    <Typography paragraph>
                        Satlas consists of an ever-expanding collection of AI-generated geospatial
                        data products. These products are high-accuracy, global, and updated
                        monthly.
                    </Typography>
                </MaxWidthText>
                <MaxWidthContent>
                    <ExampleGrid container columnSpacing={2.5} rowSpacing={2}>
                        {examples.map((ex) => (
                            <Grid item xs={12} sm={6} md={3}>
                                <Card>
                                    <CardActionArea href={ex.url}>
                                        <CardMedia
                                            component="img"
                                            height="140"
                                            image={ex.src}
                                            alt={ex.alt}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {ex.label}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </ExampleGrid>
                </MaxWidthContent>
            </StackableSection>

            <StackableGreySection>
                <MaxWidthText>
                    <h3>Download</h3>
                    <Typography paragraph>
                        Our geospatial data products are produced by analyzing public domain imagery
                        captured by the European Space Agency’s Sentinel-2 satellites. All of our
                        AI-generated products are freely available and can be downloaded for offline
                        analysis. We make this data available in either GeoJSON or GeoTIFF formats.
                    </Typography>
                    <Typography>
                        All data is released under the open{' '}
                        <a
                            href="https://github.com/allenai/satlas/blob/main/DataLicense"
                            target="_blank"
                            rel="noopener noreferrer">
                            ODC-BY license
                        </a>
                        .
                    </Typography>
                    <BigButton href="https://github.com/allenai/satlas/blob/main/GeospatialDataProducts.md">
                        Download Data
                    </BigButton>
                    <Typography sx={{ mt: 4 }}>
                        The data generated by our AI models has high accuracy. However, AI systems
                        are never perfect and several factors tend to degrade performance. An
                        analysis of the accuracy of each Satlas geospatial data product is made
                        available in our{' '}
                        <a
                            href="https://github.com/allenai/satlas/blob/main/DataValidationReport.md"
                            target="_blank"
                            rel="noopener noreferrer">
                            Data Validation Report
                        </a>
                        .
                    </Typography>
                    <BigButton href="https://satlas.allen.ai/ai">
                        Learn more about AI in Satlas
                    </BigButton>
                    <BigButton href="https://github.com/allenai/satlas/blob/main/DataValidationReport.md">
                        View Data Validation Report
                    </BigButton>
                </MaxWidthText>
            </StackableGreySection>

            <CenterAlignedDiv>
                <Footer />
            </CenterAlignedDiv>
        </MainContainer>
    );
};

const ExampleGrid = styled(Grid)`
    padding: ${({ theme }) => `${theme.spacing(8.5)} ${theme.spacing(3)} 0 ${theme.spacing(3)}`};
    text-align: canter;

    .MuiTypography-h5 {
        margin: 0;
    }
`;
