import React, { useContext } from 'react';

import { RadioSelector } from './baseSelectors/RadioSelector';
import { MapOptionsContext } from '../context/MapOptionsContext';

export enum LayoutOption {
    Single = 'Single Map',
    SideBySide = 'Side by Side',
    Split = 'Split Screen',
    ChangeOverTime = 'Change Over Time',
}

const defaultOptions = Object.values(LayoutOption);
export const defaultValue = defaultOptions[0];

interface Props {
    options?: LayoutOption[];
}

export const LayoutSelector = ({ options = defaultOptions, ...rest }: Props) => {
    const { selectedLayout, setSelectedLayout, setShowHeatmap } = useContext(MapOptionsContext);

    if (!options.includes(selectedLayout)) {
        setSelectedLayout(options[0]);
    }

    const layoutOnChange = (value: LayoutOption) => {
        // we need to special case change over time, since we don't have heatmap capabilities
        // enabled in that layout mode yet
        if (value === LayoutOption.ChangeOverTime) {
            setShowHeatmap(false);
        }
        setSelectedLayout(value);
    };

    return (
        <RadioSelector<LayoutOption>
            options={options}
            value={selectedLayout}
            onChange={layoutOnChange}
            {...rest}
        />
    );
};
