import * as React from 'react';
import styled from 'styled-components';

const config = {
    width: 396,
    height: 64,
};

export const SatlasLogo = React.memo(
    ({ width = config.width, height = config.height }: React.SVGProps<SVGSVGElement>) => (
        <SVG
            xmlns="http://www.w3.org/2000/svg"
            viewBox={`0 0 ${config.width} ${config.height}`}
            width={width}
            height={height}
            role="img">
            <path d="M24.951,30.985C15.803,28.738,7.163,26.614,7.163,17.27c0-7.874,6.72-12.765,17.537-12.765 c5.55,0,11.425,1.877,15.714,5.017l0.38,0.278l1.432-2.086l-0.345-0.263C37.486,4.088,30.871,2,24.616,2 C9.839,2,4.575,9.843,4.575,17.186c0,11.41,9.975,13.835,19.623,16.181c9.061,2.203,17.621,4.287,17.621,13.531 c0,7.889-6.712,12.599-17.956,12.599c-7.496,0-15.124-3.064-18.985-7.624L4.57,51.509l-1.838,1.705l0.271,0.332 C7.1,58.602,15.517,62,23.947,62c15.084,0,20.458-7.8,20.458-15.101C44.405,35.767,34.515,33.337,24.951,30.985z" />
            <polygon points="97.109,2.25 94.852,2.25 67.225,61.75 70.158,61.75 95.98,6.054 121.677,61.484 121.802,61.75 124.733,61.75 97.234,2.516" />
            <polygon points="140.1,4.755 161.86,4.755 161.86,61.75 164.449,61.75 164.449,4.755 186.209,4.755 186.209,2.25 140.1,2.25" />
            <polygon points="213.302,2.25 210.708,2.25 210.708,61.75 248.203,61.75 248.203,59.245 213.302,59.245" />
            <path d="M373.809,30.985c-9.145-2.247-17.785-4.371-17.785-13.715c0-7.874,6.72-12.765,17.535-12.765 c5.55,0,11.428,1.877,15.717,5.017l0.383,0.278l1.43-2.086l-0.347-0.263C386.35,4.088,379.732,2,373.477,2 C358.7,2,353.43,9.843,353.43,17.186c0,11.41,9.977,13.835,19.623,16.181c9.063,2.203,17.621,4.287,17.621,13.531 c0,7.889-6.71,12.599-17.953,12.599c-7.496,0-15.124-3.064-18.985-7.624l-0.306-0.365l-1.833,1.705l0.266,0.332 c4.1,5.055,12.515,8.453,20.945,8.453c15.084,0,20.46-7.8,20.46-15.101C393.269,35.767,383.373,33.337,373.809,30.985z" />
            <polygon points="301.455,2.054 298.943,2.054 271.568,61.017 271.134,61.946 274.504,61.946 300.199,6.522 325.719,61.569 325.893,61.946 329.258,61.946 301.628,2.431" />
            <circle cx="96.053" cy="41.7" r="2.362" />
            <circle cx="300.198" cy="41.7" r="2.362" />
        </SVG>
    )
);

export const SVG = styled.svg`
    fill: #fff;
`;
