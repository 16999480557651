import { MaxWidthText } from '@allenai/varnish2/components';
import styled from 'styled-components';

// needed because the main app kills margins and padding
export const FullWidthMainContainer = styled.div`
    padding: ${({ theme }) => theme.spacing(3)};

    ${MaxWidthText} {
        max-width: 900px;
        margin: 0 auto;
    }
`;
