import React from 'react';

import { ImageCompare, SlideViewerGallery } from './SlideViewerGallery';

import sydneyBefore from '../images/super-res/sydney-before.jpg';
import sydneyAfter from '../images/super-res/sydney-after.jpg';
import nakuruBefore from '../images/super-res/nakuru-before.jpg';
import nakuruAfter from '../images/super-res/nakuru-after.jpg';
import newOrleansBefore from '../images/super-res/new-orleans-before.jpg';
import newOrleansAfter from '../images/super-res/new-orleans-after.jpg';
import maisseBefore from '../images/super-res/maisse-before.jpg';
import maisseAfter from '../images/super-res/maisse-after.jpg';
import losAngelesBefore from '../images/super-res/los-angeles-before.jpg';
import losAngelesAfter from '../images/super-res/los-angeles-after.jpg';
import dryTortugasBefore from '../images/super-res/dry-tortugas-before.jpg';
import dryTortugasAfter from '../images/super-res/dry-tortugas-after.jpg';

export const SuperResSlideViewer = () => {
    const slideContent: ImageCompare[] = [
        {
            leftSrc: nakuruBefore,
            rightSrc: nakuruAfter,
            caption: 'Nakuru, Kenya',
        },
        {
            leftSrc: sydneyBefore,
            rightSrc: sydneyAfter,
            caption: 'Sydney, Australia',
        },
        {
            leftSrc: newOrleansBefore,
            rightSrc: newOrleansAfter,
            caption: 'New Orleans Locks',
        },
        {
            leftSrc: maisseBefore,
            rightSrc: maisseAfter,
            caption: 'Maisse, France',
        },
        {
            leftSrc: losAngelesBefore,
            rightSrc: losAngelesAfter,
            caption: 'Los Angeles, California',
        },
        {
            leftSrc: dryTortugasBefore,
            rightSrc: dryTortugasAfter,
            caption: 'Dry Tortugas, Florida',
        },
    ];

    return (
        <SlideViewerGallery content={slideContent} leftLabel="Sentinel-2" rightLabel="Super-Res" />
    );
};
