import React, { useState } from 'react';
import styled from 'styled-components';
import TwentyTwenty from 'react-twentytwenty';

export interface ImageCompare {
    leftSrc: string;
    rightSrc: string;
    caption: string;
}
interface Props {
    content: ImageCompare[];
    leftLabel: string;
    rightLabel: string;
}

export const SlideViewerGallery = ({
    content,
    leftLabel: beforeLabel,
    rightLabel: afterLabel,
}: Props) => {
    const [activeSlide, setActiveSlide] = useState(0);

    return (
        <>
            <Container>
                <TwentyTwenty
                    left={
                        <Pane>
                            <img
                                src={content[activeSlide].leftSrc}
                                alt={content[activeSlide].caption}
                            />
                        </Pane>
                    }
                    right={
                        <Pane>
                            <img
                                src={content[activeSlide].rightSrc}
                                alt={content[activeSlide].caption}
                            />
                        </Pane>
                    }
                    slider={
                        <SlideThumb>
                            <LabelLeft>{beforeLabel}</LabelLeft>
                            <LabelRight>{afterLabel}</LabelRight>
                            <DividerLine />
                            <Target>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                                    <polygon points="23.434,50 41.751,64.811 41.751,35.189" />
                                    <polygon points="76.566,50 58.249,35.189 58.249,64.811" />
                                </svg>
                            </Target>
                        </SlideThumb>
                    }
                />
                <SlideLabel>{content[activeSlide].caption}</SlideLabel>
            </Container>
            {content.length > 1 ? (
                <>
                    <Thumbnails>
                        {content.map((slide, k) => (
                            <li key={k}>
                                <ThumbnailButton
                                    $slideActive={activeSlide === k}
                                    onClick={() => setActiveSlide(k)}
                                    title={slide.caption}>
                                    <img src={slide.rightSrc} alt={slide.caption} />
                                </ThumbnailButton>
                            </li>
                        ))}
                    </Thumbnails>
                    <BeforeImagePreloader>
                        {content.map((slide, k) => (
                            <img key={k} src={slide.leftSrc} alt={slide.caption} />
                        ))}
                    </BeforeImagePreloader>
                </>
            ) : null}
        </>
    );
};

const Pane = styled.div`
    width: 100%;
    height: 100%;

    img {
        display: block;
        width: 900px;
        height: 450px;
        position: absolute;
        left: 0;
        top: 0;
    }
`;

const Container = styled.div`
    aspect-ratio: 2 / 1;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 12px 80px rgba(0, 10, 20, 0.25);

    // TwentyTwenty structure
    & > div,
    & > div > div,
    & > div > div > div {
        height: 100%;
    }
`;

const DividerLine = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background: #fff;
    transition: opacity 0.3s ease;
    opacity: 0.75;
`;

const Target = styled.div`
    width: 32px;
    height: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100%;
    border: 3px solid rgba(255, 255, 255, 0.85);
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(8px);
    transition: border-color 0.3s ease;

    svg {
        fill: #fff;
        opacity: 0.85;
        transition: opacity 0.3s ease;
    }
`;

const SlideThumb = styled.div`
    position: absolute;
    transform: translateX(-50%);
    top: 0;
    width: 20px;
    height: 100%;
    cursor: grab;

    &:hover {
        ${DividerLine} {
            opacity: 1;
        }

        ${Target} {
            border-color: #fff;

            svg {
                opacity: 1;
            }
        }
    }

    &:active {
        cursor: grabbing;

        ${DividerLine} {
            opacity: 0.25;
        }

        ${Target} {
            border-color: rgba(255, 255, 255, 0.5);
            svg {
                opacity: 0.75;
            }
        }
    }
`;

const Label = styled.span`
    display: block;
    position: absolute;
    color: #fff;
    border: 1px solid rgba(255, 255, 255, 0.2);
    background: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(10px);
    padding: 10px 24px;
    top: 12px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
`;

const LabelLeft = styled(Label)`
    right: 11px;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
    border-right-width: 0;
    padding-right: 20px;
`;

const LabelRight = styled(Label)`
    left: 11px;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    border-left-width: 0;
    padding-left: 20px;
`;

const SlideLabel = styled(Label)`
    right: 12px;
    bottom: 12px;
    top: auto;
    height: max-content;
    border-radius: 100px;
    z-index: 2;
`;

const ThumbnailButton = styled.button<{ $slideActive?: boolean }>`
    display: block;
    border: none;
    appearance: none;
    background: silver;
    width: 40px;
    height: 20px;
    border-radius: 4px;
    transition: transform 0.3s ease, opacity 0.3s ease;
    cursor: pointer;
    padding: 0;
    overflow: hidden;
    opacity: 0.5;

    img {
        width: 40px;
        height: 20px;
        object-fit: cover;
    }

    ${({ $slideActive }) => $slideActive && 'transform: scale(1.2); opacity: 1;'}

    &:hover {
        transform: scale(1.2);
        opacity: 1;
    }

    &:active {
        opacity: 0.67;
        transform: scale(1.1);
        transition-duration: 0s;
    }
`;

const Thumbnails = styled.ul`
    list-style: none;
    padding: 0;
    display: flex;
    margin: 20px auto 0 auto;
    justify-content: center;
    gap: 8px;
    position: relative;
    z-index: 2;
`;

// This makes UX smoother by preloading before states
// After states are already preloaded via thumbnail images
const BeforeImagePreloader = styled.div`
    position: relative;

    img {
        position: absolute;
        width: 0;
        height: 0;
    }
`;
