/*
  Slider that takes in a set of ordered options and allows the user to select a range, and
  slide the start and end of the range further out/in.
  It does this by using the index of the option as the number value for the slider range start and end.
*/

import React, { useState } from 'react';
import styled from 'styled-components';
import { SliderValueLabelProps, Tooltip, Box } from '@mui/material';

import { BaseSlider, sliderHeightScalingValue } from './BaseSlider';

interface Props<T> {
    options: T[];
    optionsToLabel: T[];
    startValue: T;
    endValue: T;
    onStartValueChange: (value: T) => void;
    onEndValueChange: (value: T) => void;
    className?: string;
}

export const RangeSliderSelector = <T extends string>({
    options,
    optionsToLabel,
    startValue,
    endValue,
    onStartValueChange,
    onEndValueChange,
    className,
}: Props<T>) => {
    // local number value array to be used as the slider range values
    const [sliderRangeValues, setSliderRangeValues] = useState<number[]>([
        options.indexOf(startValue) || 1,
        options.indexOf(endValue) || 2,
    ]);

    const handleChange = (_event: Event, newValue: number | number[]) => {
        setSliderRangeValues(newValue as number[]);
        onStartValueChange(options[sliderRangeValues[0]]);
        onEndValueChange(options[sliderRangeValues[1]]);
    };

    const convertNumberToDisplayValue = (value: number) => {
        // logic to add "Start" and "End" labels to the slider
        if (sliderRangeValues.length >= 2) {
            if (value === sliderRangeValues[0]) {
                return 'Start Time: ' + options[value];
            } else if (value === sliderRangeValues[1]) {
                return 'End Time: ' + options[value];
            }
        }
        return options[value];
    };

    const ValueLabelComponent = (props: SliderValueLabelProps) => {
        const { children, value } = props;
        return (
            <Tooltip enterTouchDelay={0} placement="top" title={value}>
                {children}
            </Tooltip>
        );
    };

    return (
        <Container sx={{ height: `${optionsToLabel.length * sliderHeightScalingValue}px` }}>
            <BaseSlider
                disableSwap
                className={className}
                track={false}
                value={sliderRangeValues}
                orientation="vertical"
                onChange={handleChange}
                getAriaValueText={convertNumberToDisplayValue}
                valueLabelFormat={convertNumberToDisplayValue}
                min={0}
                max={options.length - 1 || 1}
                step={1}
                valueLabelDisplay="auto"
                slots={{
                    valueLabel: ValueLabelComponent,
                }}
                marks={optionsToLabel.map((v) => {
                    return { value: options.indexOf(v), label: v };
                })}
            />
        </Container>
    );
};

const Container = styled(Box)`
    margin: ${({ theme }) => `${theme.spacing(2)} 0 ${theme.spacing(2)} ${theme.spacing(3)}`};
`;
