import { color2 } from '@allenai/varnish2/theme';

import { FeatureCategories } from '../../data/layers';
import { MarineInfraLabels } from '../MarineInfraSelector';

export const getMarineInfraColors = (marineInfraLabel: string) => {
    switch (marineInfraLabel) {
        case MarineInfraLabels.OffshorePlatforms:
        case FeatureCategories.OffshorePlatforms:
            return color2.F3.hex;
        case MarineInfraLabels.OffshoreTurbines:
        case FeatureCategories.OffshoreTurbines:
        default:
            return color2.P3.hex;
    }
};

export const getMarineInfraUnit = (_marineInfraLabel?: string) => {
    return '';
};

export const marineInfraLabelColor = color2.N1.hex;
