import { AnyLayer } from 'mapbox-gl';
import { color2 } from '@allenai/varnish2/theme';

import { DateOption, options as dates } from '../components/YearSelector';
import { TreeCoverFillOption } from '../components/TreeCoverFillSelector';
import { ApplicationOption } from '../components/ApplicationSelector';
import { LayoutOption } from '../components/LayoutSelector';
import { CheckboxType } from '../components/baseSelectors/CheckboxSelector';
import {
    FullKey,
    HighKey,
    LowKey,
    MediumKey,
    NoneKey,
    getTreeCoverColors,
} from '../components/utils/treeCover';
import {
    AddedKey,
    RemovedKey,
    UnchangedKey,
    getChangeOverTimeColors,
} from '../components/utils/changeOverTime';
import {
    getAddedTimeFilter,
    addedTreeCoverFilter,
    removedTreeCoverFilter,
    unchangedTreeCoverFilter,
    getRemovedTimeFilter,
    getUnchangedTimeFilter,
} from '../components/utils/filters';
import { MapSources, sentinel2023SpecialDate } from './sources';
import { MarineInfraLabels, MarineInfraOption } from '../components/MarineInfraSelector';
import { getMarineInfraColors } from '../components/utils/marineInfra';
import {
    RenewableEnergyLabels,
    RenewableEnergyOption,
} from '../components/RenewableEnergySelector';
import { getRenewableEnergyColors } from '../components/utils/renewableEnergy';

// options set that affect what layers to show
interface SelectedMapOptions {
    selectedApplication: ApplicationOption;
    selectedLayout: LayoutOption;
    showHeatmap: boolean;
    showSatellite: boolean;
    showSuperResolution: boolean;
    hideAnnotation: boolean;
    selectedDateOption: DateOption;
    selectedTreeCoverFill: TreeCoverFillOption;
    selectedMarineInfra: MarineInfraOption;
    selectedRenewableEnergy: RenewableEnergyOption;
    selectedStartDateOption: DateOption;
    selectedEndDateOption: DateOption;
    selectedDateOptionSecondary: DateOption;
    isSecondary: boolean;
}

export enum FeatureCategories {
    OffshoreTurbines = 'offshore_wind_turbine',
    OffshorePlatforms = 'offshore_platform',
    SolarFarms = 'solar_farm',
    OnshoreWindTurbines = 'wind_turbine',
}

export enum MapLayers {
    Marine = 'marine',
    Renewable = 'renewable',
    TreeCover = 'tree_cover',
}

export const getLayers = (getMapOptions: () => SelectedMapOptions): AnyLayer[] => {
    const mapOptions = getMapOptions();
    const mapboxLayer = getMapboxLayer();

    const marineInfraOnArray: string[] = [];
    mapOptions.selectedMarineInfra.forEach((infra) => {
        if (infra.isChecked) {
            if (infra.label === MarineInfraLabels.OffshorePlatforms) {
                marineInfraOnArray.push(FeatureCategories.OffshorePlatforms);
            }
            if (infra.label === MarineInfraLabels.OffshoreTurbines) {
                marineInfraOnArray.push(FeatureCategories.OffshoreTurbines);
            }
        }
    });

    const renewableEnergyOnArray: string[] = [];
    mapOptions.selectedRenewableEnergy.forEach((infra) => {
        if (infra.isChecked) {
            if (infra.label === RenewableEnergyLabels.SolarFarms) {
                renewableEnergyOnArray.push(FeatureCategories.SolarFarms);
            }
            if (infra.label === RenewableEnergyLabels.OnshoreWindTurbines) {
                renewableEnergyOnArray.push(FeatureCategories.OnshoreWindTurbines);
            }
        }
    });

    const showSuperResolution =
        mapOptions.selectedApplication === ApplicationOption.SuperResolution &&
        mapOptions.showSuperResolution;
    const showSentinel2023 =
        mapOptions.showSatellite &&
        mapOptions.selectedApplication === ApplicationOption.SuperResolution;

    const showSentinel =
        mapOptions.showSatellite &&
        mapOptions.selectedApplication !== ApplicationOption.SuperResolution;
    const showMarineInfraNoDiff =
        mapOptions.selectedApplication === ApplicationOption.MarineInfra &&
        mapOptions.selectedLayout !== LayoutOption.ChangeOverTime;
    const showDiffMarineInfra =
        mapOptions.selectedApplication === ApplicationOption.MarineInfra &&
        mapOptions.selectedLayout === LayoutOption.ChangeOverTime;
    const showRenewableEnergyNoDiff =
        mapOptions.selectedApplication === ApplicationOption.RenewableEnergy &&
        mapOptions.selectedLayout !== LayoutOption.ChangeOverTime;
    const showRenewableEnergy =
        mapOptions.selectedApplication === ApplicationOption.RenewableEnergy &&
        mapOptions.selectedLayout === LayoutOption.ChangeOverTime;
    const showTreeCover =
        mapOptions.selectedApplication === ApplicationOption.TreeCover &&
        mapOptions.selectedLayout !== LayoutOption.ChangeOverTime;
    const showDiffTreeCover =
        mapOptions.selectedApplication === ApplicationOption.TreeCover &&
        mapOptions.selectedLayout === LayoutOption.ChangeOverTime;

    const dateStart = mapOptions.selectedStartDateOption;
    const dateEnd = mapOptions.selectedEndDateOption;

    const addedTimeFilter = getAddedTimeFilter(dateStart, dateEnd);
    const removedTimeFilter = getRemovedTimeFilter(dateStart, dateEnd);
    const unchangedTimeFilter = getUnchangedTimeFilter(dateStart, dateEnd);

    const baseLayers = [
        // osm
        ...mapboxLayer,

        // sentinel2 -- note that this should always be at the start of the layers array so annotations come
        // on top of satellite imagery.
        ...(showSentinel
            ? dates.flatMap((y) => getSatelliteLayer('sentinel2', mapOptions, y))
            : []),
    ];

    const annotationsLayers = [
        // marine infra heatmap
        ...(showMarineInfraNoDiff && mapOptions.showHeatmap
            ? getOffshoreInfraLayersHeatmap(
                  MapSources.HistoryMarineInfra,
                  marineInfraOnArray,
                  mapOptions
              )
            : []),

        // marine infra non-heatmap
        ...(showMarineInfraNoDiff && !mapOptions.showHeatmap
            ? getOffshoreInfraLayers(MapSources.HistoryMarineInfra, marineInfraOnArray, mapOptions)
            : []),

        // renewable energy heatmap
        ...(showRenewableEnergyNoDiff && mapOptions.showHeatmap
            ? getRenewableEnergyLayersHeatmap(
                  MapSources.HistoryRenewableEnergy,
                  renewableEnergyOnArray,
                  mapOptions
              )
            : []),

        // renewable energy non-heatmap
        ...(showRenewableEnergyNoDiff && !mapOptions.showHeatmap
            ? getRenewableEnergyLayers(
                  MapSources.HistoryRenewableEnergy,
                  renewableEnergyOnArray,
                  mapOptions
              )
            : []),

        // tree cover
        ...(showTreeCover
            ? getTreeCoverLayer(MapSources.HistoryTreeCover, MapLayers.TreeCover, mapOptions)
            : []),

        // diff over time layout for marine infra
        ...(showDiffMarineInfra
            ? getDiffMarineInfraLayers(
                  MapSources.HistoryMarineInfra,
                  marineInfraOnArray,
                  addedTimeFilter,
                  removedTimeFilter,
                  unchangedTimeFilter
              )
            : []),

        // diff over time layout for marine infra
        ...(showRenewableEnergy
            ? getDiffRenewableEnergyLayers(
                  MapSources.HistoryRenewableEnergy,
                  renewableEnergyOnArray,
                  addedTimeFilter,
                  removedTimeFilter,
                  unchangedTimeFilter
              )
            : []),

        // diff over time layout for tree cover
        ...(showDiffTreeCover
            ? getAddedAndRemovedTreeCoverLayers(
                  MapSources.ChangeOverTimeTreeCover,
                  MapLayers.TreeCover
              )
            : []),
    ];

    // non-annotation layers, such as super resolution
    const additionalFeatureLayers = [
        ...(showSuperResolution ? [getSatelliteLayer(MapSources.SuperResolution, mapOptions)] : []),
        // in the case that we want to get Sentinel2023 data for Super Resolution comparison, we're pinning
        // to only show April 2023 for now. This will change in the future once we have super res data for
        // more dates.
        ...(showSentinel2023
            ? [getSatelliteLayer('sentinel2', mapOptions, sentinel2023SpecialDate)]
            : []),
    ];

    if (mapOptions.hideAnnotation) {
        return [...baseLayers, ...additionalFeatureLayers];
    } else {
        return [...baseLayers, ...annotationsLayers, ...additionalFeatureLayers];
    }
};

const HeatmapPaint = { 'heatmap-radius': 30, 'heatmap-opacity': 0.33 };

// Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
const standardCircleRadius = [
    'step',
    ['coalesce', ['get', 'point_count'], 1],
    10,
    8 * Math.pow(2, 1),
    10 + 5 * 1.5,
    8 * Math.pow(2, 2),
    10 + 5 * 2,
    8 * Math.pow(2, 3),
    10 + 5 * 2.5,
    8 * Math.pow(2, 4),
    10 + 5 * 3,
    8 * Math.pow(2, 5),
    10 + 5 * 3.5,
    8 * Math.pow(2, 6),
    10 + 5 * 4,
    8 * Math.pow(2, 7),
    10 + 5 * 4.5,
    8 * Math.pow(2, 8),
    10 + 5 * 5,
];

const MarineDataPointPaint: any = {
    'circle-color': [
        'match',
        ['get', 'category'],
        FeatureCategories.OffshorePlatforms,
        getMarineInfraColors(FeatureCategories.OffshorePlatforms),
        FeatureCategories.OffshoreTurbines,
        getMarineInfraColors(FeatureCategories.OffshoreTurbines),
        /* other */ color2.P3.hex,
    ],
    'circle-radius': standardCircleRadius,
    'circle-opacity': 0.7,
};

const RenewableDataPointPaint: any = {
    'circle-color': [
        'match',
        ['get', 'category'],
        FeatureCategories.SolarFarms,
        getRenewableEnergyColors(FeatureCategories.SolarFarms),
        FeatureCategories.OnshoreWindTurbines,
        getRenewableEnergyColors(FeatureCategories.OnshoreWindTurbines),
        /* other */ color2.P3.hex,
    ],
    'circle-radius': standardCircleRadius,
    'circle-opacity': 0.7,
};

const getOffshoreInfraLayersHeatmap = (
    source: string,
    features: string[],
    mapOptions: SelectedMapOptions
): AnyLayer[] => {
    const mapYear = !mapOptions.isSecondary
        ? mapOptions.selectedDateOption
        : mapOptions.selectedDateOptionSecondary;
    const yearFilter = ['all', ['<=', ['get', 'start'], mapYear], ['>', ['get', 'end'], mapYear]];
    const categoryArray: any[] = [];
    features.map((f: string) => categoryArray.push(['==', ['get', 'category'], f]));
    const categoryFilter = ['any', ...categoryArray];

    return [
        {
            id: 'marine_hm',
            type: 'heatmap',
            source: source,
            minzoom: 0,
            maxzoom: 23,
            'source-layer': MapLayers.Marine,
            paint: HeatmapPaint,
            filter: ['all', yearFilter, categoryFilter],
        },
    ];
};

const getOffshoreInfraLayers = (
    source: string,
    features: string[],
    mapOptions: SelectedMapOptions
): AnyLayer[] => {
    const mapYear = !mapOptions.isSecondary
        ? mapOptions.selectedDateOption
        : mapOptions.selectedDateOptionSecondary;
    const yearFilter = ['all', ['<=', ['get', 'start'], mapYear], ['>', ['get', 'end'], mapYear]];
    const categoryArray: any[] = [];
    features.map((f: string) => categoryArray.push(['==', ['get', 'category'], f]));
    const categoryFilter = ['any', ...categoryArray];

    return [
        {
            id: 'marine_no_hm',
            type: 'circle',
            source: source,
            minzoom: 0,
            maxzoom: 23,
            'source-layer': MapLayers.Marine,
            paint: MarineDataPointPaint,
            filter: ['all', yearFilter, categoryFilter],
        },
    ];
};

const getSolarFilterToPointsOnly = (points: boolean) => [
    'all',
    ['==', ['get', 'category'], FeatureCategories.SolarFarms],
    ['==', ['get', 'point'], points],
];

const getRenewableEnergyLayersHeatmap = (
    source: string,
    features: string[],
    mapOptions: SelectedMapOptions
): AnyLayer[] => {
    const mapYear = !mapOptions.isSecondary
        ? mapOptions.selectedDateOption
        : mapOptions.selectedDateOptionSecondary;
    const yearFilter = ['all', ['<=', ['get', 'start'], mapYear], ['>', ['get', 'end'], mapYear]];
    const categoryArray: any[] = [];
    features.map((f: string) =>
        categoryArray.push(
            f === FeatureCategories.SolarFarms
                ? getSolarFilterToPointsOnly(true) // we want to ignore the solar farms with OUT the point attribute
                : ['==', ['get', 'category'], f]
        )
    );
    const categoryFilter = ['any', ...categoryArray];

    return [
        {
            id: 'renewable_hm',
            type: 'heatmap',
            source: source,
            minzoom: 0,
            maxzoom: 23,
            'source-layer': MapLayers.Renewable,
            paint: HeatmapPaint,
            filter: ['all', yearFilter, categoryFilter],
        },
    ];
};

const getRenewableEnergyLayers = (
    source: string,
    features: string[],
    mapOptions: SelectedMapOptions
): AnyLayer[] => {
    const mapYear = !mapOptions.isSecondary
        ? mapOptions.selectedDateOption
        : mapOptions.selectedDateOptionSecondary;
    const yearFilter = ['all', ['<=', ['get', 'start'], mapYear], ['>', ['get', 'end'], mapYear]];

    // we grab all features other than solar farm, and we treat them generically
    const categoryArray: any[] = features
        .filter((f: string) => f !== FeatureCategories.SolarFarms)
        .map((f: string) => ['==', ['get', 'category'], f]);
    const allOtherCategoryFilter = ['any', ...categoryArray];
    const layers: AnyLayer[] = [
        // first add the generic layer that has all wexcept soloar farms
        {
            id: 'renewable_no_hm',
            type: 'circle',
            source: source,
            minzoom: 0,
            maxzoom: 23,
            'source-layer': MapLayers.Renewable,
            paint: RenewableDataPointPaint,
            filter: ['all', yearFilter, allOtherCategoryFilter],
        },
    ];

    // then add in solar farms which are special by how we display different things at different levels
    const switchSolarFarmRenderAtZoom = 8;
    if (features.includes(FeatureCategories.SolarFarms)) {
        layers.push(
            // when zoomed in, outline to make them easier to see
            {
                id: 'renewable_solar_farm_line',
                type: 'line',
                source: source,
                minzoom: switchSolarFarmRenderAtZoom,
                maxzoom: 23,
                'source-layer': MapLayers.Renewable,
                paint: {
                    'line-color': getRenewableEnergyColors(FeatureCategories.SolarFarms),
                    'line-width': 4,
                },
                filter: ['all', yearFilter, getSolarFilterToPointsOnly(false)],
            },
            //  when zoomed in, polygon fill
            {
                id: 'renewable_solar_farm_fill',
                type: 'fill',
                source: source,
                minzoom: switchSolarFarmRenderAtZoom,
                maxzoom: 23,
                'source-layer': MapLayers.Renewable,
                paint: {
                    'fill-color': getRenewableEnergyColors(FeatureCategories.SolarFarms),
                    'fill-opacity': 0.25,
                },
                filter: ['all', yearFilter, getSolarFilterToPointsOnly(false)],
            },
            // when zoomed in, we also show a hidden circle which will be used later for calculating details
            // the details are not calculated using any of the line/fill data, only the point data
            // we do this by choice because of how the solar farms overlap, other features could do it differently
            {
                id: 'renewable_solar_farm_point_hidden',
                type: 'circle',
                source: source,
                minzoom: switchSolarFarmRenderAtZoom,
                maxzoom: 23,
                'source-layer': MapLayers.Renewable,
                paint: {
                    'circle-radius': 8,
                    'circle-color': 'transparent',
                },
                filter: ['all', yearFilter, getSolarFilterToPointsOnly(true)],
            },
            // but if we zoom out, then show a circle
            {
                id: 'renewable_solar_farm_point',
                type: 'circle',
                source: source,
                minzoom: 0,
                maxzoom: switchSolarFarmRenderAtZoom,
                'source-layer': MapLayers.Renewable,
                paint: RenewableDataPointPaint,
                filter: ['all', yearFilter, getSolarFilterToPointsOnly(true)],
            }
        );
    }

    return layers;
};

const getAddedAndRemovedTreeCoverLayers = (source: string, feature: string): AnyLayer[] => {
    return [
        {
            id: `${feature}_unchanged`,
            type: 'fill',
            source,
            minzoom: 0,
            maxzoom: 23,
            filter: unchangedTreeCoverFilter,
            'source-layer': feature,
            paint: {
                'fill-color': getChangeOverTimeColors(UnchangedKey),
                'fill-opacity': 0.4,
            },
        },
        {
            id: `${feature}_added`,
            type: 'fill',
            source,
            minzoom: 0,
            maxzoom: 23,
            filter: addedTreeCoverFilter,
            'source-layer': feature,
            paint: {
                'fill-color': getChangeOverTimeColors(AddedKey),
                'fill-opacity': 0.8,
            },
        },
        {
            id: `${feature}_removed`,
            type: 'fill',
            source,
            minzoom: 0,
            maxzoom: 23,
            filter: removedTreeCoverFilter,
            'source-layer': feature,
            paint: {
                'fill-color': getChangeOverTimeColors(RemovedKey),
                'fill-opacity': 0.8,
            },
        },
    ];
};

const getDiffItemLayers = (
    source: string,
    mapLayer: MapLayers,
    features: string[],
    addedFilter: any[],
    removedFilter: any[],
    unchangedFilter: any[]
): AnyLayer[] => {
    const categoryArray: any[] = [];
    features.map((f: string) => categoryArray.push(['==', ['get', 'category'], f]));
    const categoryFilter = ['any', ...categoryArray];
    return [
        {
            id: `${source}_unchanged`,
            type: 'circle',
            source: source,
            minzoom: 0,
            maxzoom: 23,
            'source-layer': mapLayer,
            filter: ['all', unchangedFilter, categoryFilter],
            paint: {
                'circle-radius': 8,
                'circle-color': 'transparent',
                'circle-stroke-width': 3,
                'circle-stroke-color': getChangeOverTimeColors(UnchangedKey),
                'circle-stroke-opacity': 0.7,
            },
        },
        {
            id: `${source}_added`,
            type: 'circle',
            source: source,
            minzoom: 0,
            maxzoom: 23,
            'source-layer': mapLayer,
            filter: ['all', addedFilter, categoryFilter],
            paint: {
                'circle-radius': 8,
                'circle-color': 'transparent',
                'circle-stroke-width': 3,
                'circle-stroke-color': getChangeOverTimeColors(AddedKey),
                'circle-stroke-opacity': 0.7,
            },
        },
        {
            id: `${source}_removed`,
            type: 'circle',
            source: source,
            minzoom: 0,
            maxzoom: 23,
            'source-layer': mapLayer,
            filter: ['all', removedFilter, categoryFilter],
            paint: {
                'circle-radius': 8,
                'circle-color': 'transparent',
                'circle-stroke-width': 3,
                'circle-stroke-color': getChangeOverTimeColors(RemovedKey),
                'circle-stroke-opacity': 0.7,
            },
        },
    ];
};

const getDiffMarineInfraLayers = (
    source: string,
    features: string[],
    addedFilter: any[],
    removedFilter: any[],
    unchangedFilter: any[]
): AnyLayer[] => {
    return getDiffItemLayers(
        source,
        MapLayers.Marine,
        features,
        addedFilter,
        removedFilter,
        unchangedFilter
    );
};

const getDiffRenewableEnergyLayers = (
    source: string,
    features: string[],
    addedFilter: any[],
    removedFilter: any[],
    unchangedFilter: any[]
): AnyLayer[] => {
    return getDiffItemLayers(
        source,
        MapLayers.Renewable,
        features,
        addedFilter,
        removedFilter,
        unchangedFilter
    );
};

const getMapboxLayer = (): AnyLayer[] => {
    return [
        {
            id: 'osm',
            type: 'raster',
            source: 'mapbox-streets',
            minzoom: 0,
            maxzoom: 23,
        },
    ];
};

const getSatelliteLayer = (
    source: string,
    mapOptions: SelectedMapOptions,
    DateOption?: DateOption
): AnyLayer => {
    const mapYear = !mapOptions.isSecondary
        ? mapOptions.selectedDateOption
        : mapOptions.selectedDateOptionSecondary;

    let srcId = source;
    if (DateOption) {
        srcId += DateOption;
    }

    return {
        id: srcId,
        type: 'raster',
        source: srcId,
        minzoom: 0,
        maxzoom: 23,
        layout: {
            visibility: DateOption
                ? mapYear === DateOption || DateOption === sentinel2023SpecialDate
                    ? 'visible'
                    : 'none'
                : 'visible',
        },
    };
};

const getTreeCoverLayer = (
    source: string,
    feature: string,
    mapOptions: SelectedMapOptions
): AnyLayer[] => {
    const mapYear = !mapOptions.isSecondary
        ? mapOptions.selectedDateOption
        : mapOptions.selectedDateOptionSecondary;

    const fillArray: any[] = [];
    mapOptions.selectedTreeCoverFill.map(
        (fill: CheckboxType) =>
            fill.isChecked && fillArray.push(['==', ['get', 'level'], fill.label.toLowerCase()])
    );
    const fillFilter = ['any', ...fillArray];
    const dateFilter = ['all', ['<=', ['get', 'start'], mapYear], ['>', ['get', 'end'], mapYear]];

    return [
        {
            id: `${feature}_${mapYear}`,
            type: 'fill',
            source,
            minzoom: 0,
            maxzoom: 23,
            'source-layer': feature,
            filter: ['all', dateFilter, fillFilter],
            paint: {
                'fill-color': [
                    'match',
                    ['get', 'level'],
                    NoneKey,
                    getTreeCoverColors(NoneKey),
                    LowKey,
                    getTreeCoverColors(LowKey),
                    MediumKey,
                    getTreeCoverColors(MediumKey),
                    HighKey,
                    getTreeCoverColors(HighKey),
                    FullKey,
                    getTreeCoverColors(FullKey),
                    /* other */ color2.O1.hex,
                ],
                'fill-opacity': 0.5,
                'fill-outline-color': 'transparent',
            },
        },
    ];
};
