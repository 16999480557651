import React from 'react';
import styled from 'styled-components';
import { Grid, Typography } from '@mui/material';
import { Footer, MaxWidthText } from '@allenai/varnish2/components';

import {
    BigButton,
    FullWidthImageWithAttribution,
    MainContainer,
    StackableBlackSection,
    StackableGreySection,
    StackableSection,
} from '../components/SharedContainers';
import { SubPageHero } from '../components/SubPageHero';

import aiDatasetSrc from '../images/ai-dataset.svg';
import aiOverviewSrc from '../images/ai_overview.svg';
import modelSrc from '../images/model.svg';
import solarFarmsSrc from '../images/solar-farms.png';
import windTurbinesSrc from '../images/wind-turbines.png';
import offshorePlatformsSrc from '../images/offshore-platforms.png';
import { CenterAlignedDiv } from '../components/utils/sharedStyles';

export const Ai = () => {
    return (
        <MainContainer>
            <SubPageHero>
                <h1>AI in Satlas</h1>
            </SubPageHero>

            <StackableGreySection>
                <MaxWidthText>
                    <Grid container columnSpacing={5} rowSpacing={3} alignItems="center">
                        <Grid item xs={12} sm={6}>
                            <Typography paragraph>
                                Satlas combines the power of modern AI with the scale of public
                                domain satellite imagery to provide monthly monitoring of the
                                planet.
                            </Typography>
                            <Typography paragraph>
                                AI models in Satlas process freely available satellite images
                                captured by the European Space Agency’s Sentinel-2 satellites. These
                                images cover the majority of the planet every week, but are low in
                                resolution, making them difficult to interpret even for humans.
                            </Typography>
                            <Typography paragraph>
                                We leverage the latest advances in AI to robustly process images in
                                this challenging domain and produce a variety of geospatial data
                                products that we make freely available.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FullWidthImageWithAttribution>
                                <img
                                    src={aiOverviewSrc}
                                    alt="AI models process Sentinel-2 satellite images to update the geospatial data products to reflect changes like forest loss or new solar farms"
                                />
                            </FullWidthImageWithAttribution>
                        </Grid>
                    </Grid>
                </MaxWidthText>
            </StackableGreySection>

            <StackableSection>
                <MaxWidthText>
                    <h3>SatlasPretrain</h3>
                    <Typography paragraph>
                        Our AI models are pre-trained on a new large-scale remote sensing dataset
                        called SatlasPretrain. This vast dataset contains over 30 TB of imagery with
                        302 million labels spanning 137 diverse categories, from tree cover and crop
                        fields to wind farms and oil wells. Pre-training on SatlasPretrain teaches
                        AI models to understand geographically and seasonally diverse satellite
                        images.
                    </Typography>
                    <FullWidthImageWithAttribution>
                        <img
                            src={aiDatasetSrc}
                            alt="Examples of the diverse satellite images and labels in SatlasPretrain"
                        />
                        <Typography variant="caption">
                            SatlasPretrain appeared at the 2023 International Conference on Computer
                            Vision (ICCV 2023)
                        </Typography>
                    </FullWidthImageWithAttribution>
                    <BigButton href="https://satlas-pretrain.allen.ai">Learn More</BigButton>
                    <BigButton href="https://arxiv.org/abs/2211.15660">Read the Paper</BigButton>
                    <BigButton href="https://github.com/allenai/satlas/blob/main/SatlasPretrain.md">
                        Download SatlasPretrain
                    </BigButton>
                </MaxWidthText>
            </StackableSection>

            <StackableBlackSection>
                <MaxWidthText>
                    <h3>Fine-tuning the Models</h3>
                    <Typography>
                        The AI models in Satlas are fine tuned on high-quality training datasets
                        that we hand-label for each geospatial data product. In practice, we use
                        multiple steps of fine-tuning, testing, and additional labeling to
                        progressively improve the accuracy of our models.
                    </Typography>
                    <BigButton href="https://github.com/allenai/satlas#training-data-and-models">
                        Access the Training Data
                    </BigButton>
                    <FineTuningGrid container columnSpacing={2.5} rowSpacing={2}>
                        <Grid item xs={12} sm={4}>
                            <img
                                src={solarFarmsSrc}
                                height={186}
                                alt="Solar Farms outlined by Satlas"
                            />
                            <Typography variant="caption" display="block">
                                Solar Farms
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <img
                                src={windTurbinesSrc}
                                height={186}
                                alt="Wind Turbines outlined by Satlas"
                            />
                            <Typography variant="caption" display="block">
                                Wind Turbines
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <img
                                src={offshorePlatformsSrc}
                                height={186}
                                alt="Offshore Platforms outlined by Satlas"
                            />
                            <Typography variant="caption" display="block">
                                Offshore Platforms
                            </Typography>
                        </Grid>
                    </FineTuningGrid>
                </MaxWidthText>
            </StackableBlackSection>

            <StackableSection>
                <MaxWidthText>
                    <h3>AI Model Architecture</h3>
                    <Typography paragraph>
                        Our AI models use state of the art machine learning architectures and
                        training methods. They input a sequence of the three most recent satellite
                        images captured at each location. Each image is passed through a Swin
                        Transformer backbone to extract features. These features are then combined
                        via max temporal pooling, and passed to task-specific neural network heads
                        to make the final predictions.
                    </Typography>
                    <FullWidthImageWithAttribution>
                        <img src={modelSrc} alt="The architecture for the AI models in SATLAS" />
                    </FullWidthImageWithAttribution>{' '}
                </MaxWidthText>
            </StackableSection>

            <StackableGreySection>
                <MaxWidthText>
                    <h3>Data Accuracy</h3>
                    <Typography>
                        A per-continent breakdown of the accuracy of each Satlas geospatial data
                        product is made available in our{' '}
                        <a
                            href="https://github.com/allenai/satlas/blob/main/DataValidationReport.md"
                            target="_blank"
                            rel="noopener noreferrer">
                            Data Validation Report
                        </a>
                        . We also showcase examples of the limitations of our data, including
                        erroneous and missing data. Overall, the data generated by our AI models has
                        high accuracy, but AI systems are never perfect and several factors tend to
                        degrade performance.
                    </Typography>
                    <BigButton href="https://github.com/allenai/satlas/blob/main/DataValidationReport.md">
                        View Data Validation Report
                    </BigButton>
                </MaxWidthText>
            </StackableGreySection>

            <CenterAlignedDiv>
                <Footer />
            </CenterAlignedDiv>
        </MainContainer>
    );
};

const FineTuningGrid = styled(Grid)`
    padding: ${({ theme }) => `${theme.spacing(8.5)} ${theme.spacing(3)} 0 ${theme.spacing(3)}`};
    justify-content: center;

    img {
        display: block;
        margin: 0 auto;
        object-fit: cover;
        width: 100%;
        max-width: 186px;
        max-height: 186px;
    }

    .MuiTypography-caption {
        margin-top: ${({ theme }) => theme.spacing(2)};
        text-align: center;
        color: white;
    }
`;
