/**
 * This is the main entry point for the UI. You should not need to make any
 * changes here unless you want to update the theme.
 *
 * @see https://github.com/allenai/varnish
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { VarnishApp } from '@allenai/varnish2/components';
import styled from 'styled-components';

import { MapOptionsContextProvider } from './context/MapOptionsContext';
import { App } from './App';

const VarnishedApp = () => (
    <BrowserRouter>
        <StyledVarnishApp layout="left-aligned">
            <MapOptionsContextProvider>
                <App />
            </MapOptionsContextProvider>
        </StyledVarnishApp>
    </BrowserRouter>
);

// styling the content component so that the map spans the full page
const StyledVarnishApp = styled(VarnishApp)`
    &&&&& {
        min-width: 100%;
        width: 100%;
        height: 100%;
        padding: 0px;
        margin: 0px;
    }
`;

ReactDOM.render(<VarnishedApp />, document.getElementById('root'));
