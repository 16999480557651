import { color2 } from '@allenai/varnish2/theme';

export const AddedKey = 'Added';
export const RemovedKey = 'Removed';
export const UnchangedKey = 'Unchanged';

export const getChangeOverTimeColors = (key: string) => {
    switch (key) {
        case AddedKey:
            return color2.G5.hex;
        case RemovedKey:
            return color2.O5.hex;
        case UnchangedKey:
        default:
            return color2.N4.hex;
    }
};

export const getChangeOverTimeLabelColor = (key: string) => {
    switch (key) {
        default:
            return color2.N1.hex;
    }
};
