import React, { useContext } from 'react';

import { SwitchSelector } from './baseSelectors/SwitchSelector';
import { MapOptionsContext } from '../context/MapOptionsContext';
export const defaultValue = true;

interface Props {}

export const HeatSelector = ({ ...rest }: Props) => {
    const { showHeatmap, setShowHeatmap } = useContext(MapOptionsContext);
    return (
        <SwitchSelector label="Heatmap" value={showHeatmap} onChange={setShowHeatmap} {...rest} />
    );
};
