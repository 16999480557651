import React from 'react';
import styled from 'styled-components';

import { SatlasLogo } from '../images/SatlasLogo';
import wallpaper from '../images/satlas-hero-wallpaper.jpg';
import wallpaperMobile from '../images/satlas-hero-wallpaper-mobile.jpg';

interface SatlasHeroProps {
    exploreUrl: string;
}

export const SatlasHero = ({ exploreUrl }: SatlasHeroProps) => {
    return (
        <Hero>
            <HeroContent>
                <MainHeading>
                    <ScreenReaderText>Satlas</ScreenReaderText>
                    <SatlasLogo />
                </MainHeading>
                <Tagline>Open Geospatial Data Generated by AI</Tagline>
                <ButtonLink href={exploreUrl}>Explore Map</ButtonLink>
            </HeroContent>
        </Hero>
    );
};

const Hero = styled.section`
    height: 800px;
    background: #000 url('${wallpaper}') center center no-repeat;
    text-align: center;
    display: grid;
    align-items: flex-end;

    ${({ theme }) => [theme.breakpoints.down('sm')]} {
        // MUI sm breakpoint
        height: 400px;
        background-image: url('${wallpaperMobile}');
    }
`;

const HeroContent = styled.div`
    display: grid;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: 620px;
    gap: ${({ theme }) => theme.spacing(1)};

    ${({ theme }) => [theme.breakpoints.down('sm')]} {
        // MUI sm breakpoint
        height: auto;
        padding: 36px;
    }
`;

const MainHeading = styled.h1`
    margin: 0;

    ${({ theme }) => [theme.breakpoints.down('sm')]} {
        // MUI sm breakpoint
        svg {
            display: block;
            width: 100%;
        }
    }
`;

// fontSize 17px deviates from Varnish font sizes but this is a design req
const Tagline = styled.h2`
    font-size: 17px;
    font-weight: normal;
    color: ${({ theme }) => theme.color2.N3};
    margin: 0;

    & + a {
        margin-top: ${({ theme }) => theme.spacing(4)};
    }
`;

const ButtonLink = styled.a`
    display: block;
    background: ${({ theme }) => theme.color2.B3};
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    font-size: ${({ theme }) => theme.typography.fontSize};
    text-align: center;
    width: 100%;
    max-width: ${({ theme }) => theme.spacing(30)};
    padding: ${({ theme }) => theme.spacing(1.5)};
    border-radius: 7px;
    margin: 0 auto;
    transition: background-color 0.2s ease;

    &:hover {
        background: #4e7be5;
        text-decoration: none;
    }

    &:active {
        opacity: 0.8;
    }
`;

// This approach to visually hidden accessible text may seem hacky
// but it is recommended by MDN:
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#accessibility_concerns
// by linking to this solution:
// https://gomakethings.com/hidden-content-for-better-a11y/#hiding-the-link
const ScreenReaderText = styled.span`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;
