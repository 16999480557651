import styled from 'styled-components';
import { Button } from '@mui/material';
import { MaxWidthText } from '@allenai/varnish2/components';

import { FullWidthMainContainer } from './FullWidthMainContainer';

export const StackableSection = styled.div`
    padding: ${({ theme }) =>
        `${theme.spacing(8.5)} ${theme.spacing(4)} ${theme.spacing(12.5)} ${theme.spacing(4)}`};

    h2 {
        margin: ${({ theme }) => `0 0 ${theme.spacing(5)} 0`};
        text-align: center;
    }
`;

export const StackableBlackSection = styled(StackableSection)`
    background: black;
    color: white;

    h2,
    h3 {
        color: white;
    }
`;

export const StackableGreySection = styled(StackableSection)`
    background: ${({ theme }) => theme.color2.N1};
`;

export const MainContainer = styled(FullWidthMainContainer)`
    padding: 0;
`;

const NarrowMaxWidthText = styled(MaxWidthText)`
    && {
        max-width: 700px;
    }
`;

export const NarrowContent = styled(NarrowMaxWidthText)`
    && {
        margin-top: ${({ theme }) => theme.spacing(5)};
        text-align: center;
    }
`;

export const MaxWidthContent = styled(MaxWidthText)`
    && {
        max-width: 1168px;
    }
`;

export const FullWidthImageWithAttribution = styled.div`
    img {
        width: 100%;
        padding-top: ${({ theme }) => theme.spacing(4)};
    }
    .MuiTypography-caption {
        margin-top: ${({ theme }) => theme.spacing(2)};
        text-align: center;
    }
`;

export const BigButton = styled(Button).attrs({ variant: 'contained', size: 'large' })`
    && {
        margin: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(2)} 0 0`};
    }
`;
