import React, { useContext } from 'react';
import styled from 'styled-components';
import { Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

import { LayoutOption, LayoutSelector } from '../components/LayoutSelector';
import { AnimationSelector } from '../components/AnimationSelector';
import { YearSelector } from '../components/YearSelector';
import { ApplicationSelector } from '../components/ApplicationSelector';
import { MapOptionsContext } from '../context/MapOptionsContext';
import { ApplicationConfig } from './applicationConfig';
import { AccordionDrawer } from '../components/AccordionDrawer';
import { headerOffset } from '../components/utils/sharedStyles';

const options = [
    {
        Header: () => <Header>Application</Header>,
        Component: () => <ApplicationSelector />,
    },
    {
        Header: () => <Header>Layout</Header>,
        Component: () => {
            const { selectedApplication } = useContext(MapOptionsContext);
            const layoutOptions = ApplicationConfig[selectedApplication].layoutOptions;
            return <LayoutSelector options={layoutOptions} />;
        },
    },
    {
        Header: () => <Header>Time</Header>,
        Component: () => {
            const { selectedLayout, selectedApplication } = useContext(MapOptionsContext);
            const AppSpecificTimeDisplayOverride =
                ApplicationConfig[selectedApplication].TimeDisplayOverride;
            if (AppSpecificTimeDisplayOverride) {
                return <AppSpecificTimeDisplayOverride />;
            } else {
                const showAnimationToggle = selectedLayout !== LayoutOption.ChangeOverTime;
                return (
                    <>
                        <YearSelector />
                        {showAnimationToggle && <AnimationSelector />}
                    </>
                );
            }
        },
    },
    {
        Header: () => <Header>Options</Header>,
        Component: () => {
            const { selectedApplication } = useContext(MapOptionsContext);
            const AppSpecificOptions = ApplicationConfig[selectedApplication].Options;
            return <AppSpecificOptions />;
        },
    },
];

export const OptionsAsMenu = () => {
    const theme = useTheme();
    const smallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));
    return smallerThanSm ? (
        <Options>
            <Details>
                <Header>For the best experience, use Satlas on a wider screen.</Header>
            </Details>
            <Stack spacing={1}>
                {options.map((opt, i) => (
                    <React.Fragment key={i}>
                        <Divider>
                            <opt.Header />
                        </Divider>
                        <Details>{<opt.Component />}</Details>
                    </React.Fragment>
                ))}
            </Stack>
        </Options>
    ) : null;
};

export const OptionsAsAccordion = () => {
    const theme = useTheme();
    const smallerThanSm = useMediaQuery(theme.breakpoints.down('sm'));
    return !smallerThanSm ? (
        <ToolsArea spacing={1}>
            {options.map((opt, i) => (
                <AccordionDrawer key={i} header={<opt.Header />}>
                    {<opt.Component />}
                </AccordionDrawer>
            ))}
        </ToolsArea>
    ) : null;
};

const Header = styled(Typography).attrs({
    variant: 'button',
    component: 'div',
    gutterBottom: true,
})``;

const Options = styled.div`
    margin-top: ${({ theme }) => theme.spacing(2)};
    max-width: 240px;
`;

const Details = styled.div`
    padding: ${({ theme }) => `0 ${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)}`};
`;

export const ToolsArea = styled(Stack)`
    position: absolute;
    top: ${({ theme }) => theme.spacing(1)};
    left: ${({ theme }) => theme.spacing(1)};
    z-index: 999;
    width: 240px;
    max-height: calc(100vh - ${headerOffset});
    overflow-y: auto;
    direction: rtl;
    & > * {
        direction: ltr;
    }

    ::-webkit-scrollbar {
        width: 12px;
    }
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 4px grey;
    }
    ::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.color2.N2};
        border: 2px solid transparent;
        background-clip: content-box;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: ${({ theme }) => theme.color2.N3};
    }
`;
