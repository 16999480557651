import React from 'react';
import { ChipProps, Chip } from '@mui/material';

interface Props extends ChipProps {
    bgcolor: string;
    textColor: string;
    width?: string;
}

export const FeatureChip = ({ bgcolor, textColor, width = '108px', ...rest }: Props) => {
    return (
        <Chip
            sx={{
                bgcolor,
                color: textColor,
                height: 'unset',
                width: { width },
            }}
            {...rest}
        />
    );
};
