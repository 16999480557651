import React, { useCallback } from 'react';
import { FormControlLabel, Switch } from '@mui/material';

interface Props {
    label: string;
    value: boolean;
    onChange: (value: boolean) => void;
    className?: string;
}

export const SwitchSelector = ({ label, value, onChange, className }: Props) => {
    const onChangePassThrough = useCallback(
        (evt) => {
            onChange(evt.target.checked);
        },
        [onChange]
    );

    return (
        <FormControlLabel
            className={className}
            control={<Switch checked={value} onChange={onChangePassThrough} />}
            label={label}
        />
    );
};
