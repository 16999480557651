import React, { useContext } from 'react';

import { MapOptionsContext } from '../context/MapOptionsContext';

export interface Props {
    className?: string;
}

export const LatLong = ({ className }: Props) => {
    const { view, mouseLatLon } = useContext(MapOptionsContext);
    return (
        <div className={className}>
            <strong>Lat, Lon:</strong> {(mouseLatLon ? mouseLatLon[0] : view.latitude).toFixed(3)},{' '}
            {(mouseLatLon ? mouseLatLon[1] : view.longitude).toFixed(3)}
        </div>
    );
};
