import React, { useContext } from 'react';

import { SwitchSelector } from './baseSelectors/SwitchSelector';
import { MapOptionsContext } from '../context/MapOptionsContext';

export const defaultValue = true;

interface Props {}

export const SuperResolutionSelector = ({ ...rest }: Props) => {
    const { showSuperResolution, setShowSuperResolution, setShowSatellite } =
        useContext(MapOptionsContext);

    // for Satellite and Super Resolution, we want to make sure both are not on simultaneously
    const onSelectorChange = (value: boolean): void => {
        setShowSatellite(false);
        setShowSuperResolution(value);
    };
    return (
        <SwitchSelector
            label="Super Res View"
            value={showSuperResolution}
            onChange={onSelectorChange}
            {...rest}
        />
    );
};
