import React, { useEffect, useState } from 'react';
import { Table, TableHead, TableRow, TableBody } from '@mui/material';

import { DateOption } from '../YearSelector';
import { ChangeArrow, DetailsData, NarrowTableCell, getFormattedValue } from './FeatureDetails';

export interface Props {
    title: string;
    sortFn?: (a: DetailsData, b: DetailsData) => number;
    getChip: (label: string) => JSX.Element;
    date: DateOption;
    dateSecondary?: DateOption;
    data?: DetailsData[];
    isSingle: boolean;
    className?: string;
}

export const DetailsTable = ({
    className,
    title,
    sortFn = (a, b) => a.label.localeCompare(b.label),
    getChip,
    data,
    date,
    dateSecondary,
    isSingle,
}: Props) => {
    // rows holds the last known data, this prevents flickering
    const [rows, setRows] = useState<DetailsData[]>([]);
    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [data]);
    return (
        <Table className={className} size="small">
            <TableHead>
                <TableRow>
                    <NarrowTableCell colSpan={2}>
                        <strong>{title}</strong>
                    </NarrowTableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.sort(sortFn).map((row) => (
                    <React.Fragment key={row.label}>
                        <TableRow key={row.label}>
                            <NarrowTableCell>{getChip(row.label)}</NarrowTableCell>
                            {!isSingle ? (
                                <NarrowTableCell>
                                    <ChangeArrow a={row.valueSecondary || 0} b={row.value} />
                                </NarrowTableCell>
                            ) : null}
                            <NarrowTableCell align="right">
                                {isSingle ? getFormattedValue(row.value, row.unit) : null}
                            </NarrowTableCell>
                        </TableRow>
                        {!isSingle ? (
                            <React.Fragment key={row.label + date}>
                                <TableRow key={row.label + date}>
                                    <NarrowTableCell>{date}</NarrowTableCell>
                                    <NarrowTableCell align="right">
                                        {getFormattedValue(row.value, row.unit)}
                                    </NarrowTableCell>
                                </TableRow>
                                <TableRow key={row.label + dateSecondary + 2}>
                                    <NarrowTableCell>{dateSecondary}</NarrowTableCell>
                                    <NarrowTableCell align="right">
                                        {getFormattedValue(row.valueSecondary, row.unit)}
                                    </NarrowTableCell>
                                </TableRow>
                            </React.Fragment>
                        ) : null}
                    </React.Fragment>
                ))}
            </TableBody>
        </Table>
    );
};
